import { CSSProperties, useEffect, useState } from 'react';
import { Prices } from '../../../../../../models/dtos/product/prices'
import styles from './AccountPlanCard.module.scss';
import { PlanFeatureForList } from '../../../../../../models/dtos/plans/plan';
import { Subscription } from '../../../../../../models/dtos/auth/users/subscription';
import environment from '../../../../../../helpers/constants/environment';
import currencyHelper from 'helpers/curreny.helper';

type props = {
	id: string;
	name: string;
	description: string;
	priceAsMonthly: Prices;
	priceAsYearly: Prices;
	planType: number;
	isBuyable: boolean;
	features: PlanFeatureForList[];
	userSubscription?: Subscription;
	setFormVisible: Function;
	index: number;
	activeMode: string;
};

const AccountPlanCard = ({ id, name, description, activeMode, priceAsMonthly, priceAsYearly, planType, isBuyable, features, userSubscription, setFormVisible, index }: props) => {
	const [desHeight, setDesHeight] = useState<number | undefined>(undefined);
	const [conHeight, setConHeight] = useState<number | undefined>(undefined);
	const [featuresHeight, setFeaturesHeight] = useState<number | undefined>(undefined);

	const buttonTitleHandler = (name: string) => {
		if (userSubscription) {
			if (name === userSubscription.name) return 'Your Plan';
			else if (planType === 3 || !isBuyable) return 'Contact Us';
			else return 'Get Started';
		} else {
			if (planType === 2 && isBuyable) return 'Get Premium';
			else if (planType === 3 || !isBuyable) return 'Contact Us';
			else return 'Get Started';
		}
	};

	const buttonActiveHandler = () => {
		if (userSubscription) {
			if (planType === 2) {
				if (userSubscription.planAndHelper.plan.planType === 2) return true;
				else if (userSubscription.planAndHelper.plan.planType === 3) return true;
				else return false;
			} else if (planType === 3) {
				if (userSubscription.planAndHelper.plan.planType === 3) return true;
				else return false;
			}
		} else return false;
	};

	const priceHandler = () => {
		if (planType === 1) return ' - ';
		if (planType === 2) return activeMode === 'month' ? currencyHelper.formatPrice(priceAsMonthly.formattedPricePerUnit) + ' / month' : currencyHelper.formatPrice(priceAsYearly.formattedPricePerUnit / 12) + ' / month';
		if (planType === 3) return 'Custom Pricing';
	}

	const clickEvent = () => {
		if (planType === 2 && isBuyable) setFormVisible(true);
		if (planType === 3 || !isBuyable || (planType === 2 && !isBuyable)) window.open(`${environment.storeUrl}contact-us.html`);
	}

	const heightsHandler = () => {
		const descriptionElement = document.getElementsByClassName(styles.descriptionView);
		const descriptionHeightArray = Array.prototype.map.call(descriptionElement, (element) => element.getBoundingClientRect().height);
		setDesHeight(Math.max(...descriptionHeightArray as number[]))

		const featuresElement = document.getElementsByClassName(styles.featuresView);
		const featuresHeightArray = Array.prototype.map.call(featuresElement, (element) => element.getBoundingClientRect().height);
		setFeaturesHeight(Math.max(...(featuresHeightArray as number[])));
	}

	useEffect(() => {
		if (!name) return;

		heightsHandler()
	}, [name])

	useEffect(() => {
		if (!featuresHeight || !desHeight) return;

		const containerElement = document.getElementsByClassName(styles.container);
		const containerHeightArray = Array.prototype.map.call(containerElement, (element) => element.getBoundingClientRect().height);
		setConHeight(Math.max(...(containerHeightArray as number[])));
	},[featuresHeight])

	return (
		<div className={`${styles.container}${index % 2 ? ` ${styles.nonWhite}` : ''}`} style={{ '--con-height': `${conHeight}px` } as CSSProperties}>
			{userSubscription && userSubscription?.name === name ? (
				<div className={styles.yourPlan}>
					<div className={styles.yourPlanText}>Your Plan</div>
				</div>
			) : null}
			<div className={styles.titleView}>
				<label className={styles.title}>{name}</label>
			</div>

			<div className={styles.pricingView}>
				<label className={styles.pricing}>{priceHandler()}</label>
			</div>

			<div className={styles.includes}>
				<span>Included in all plans:</span>
			</div>

			<div className={styles.featuresView} style={{ '--features-height': `${featuresHeight}px` } as CSSProperties}>
				{features.map((feature, index) => (
					<div key={index} className={styles.featureView}>
						<i className="pi pi-check" />
						<label className={styles.feature}>{feature.planFeatureType.formattedDescription || feature.planFeatureType.description}</label>
					</div>
				))}
			</div>
			{planType !== 1 && (
				<div className={styles.button}>
					<button className={styles.buttonMain} onClick={clickEvent} disabled={buttonActiveHandler()}>
						<label className={styles.buttonMainText}>{buttonTitleHandler(name)}</label>
					</button>
				</div>
			)}

			{planType !== 1 && (
				<div className={styles.footerView}>
					<p className={styles.footerDescription}>{description}</p>
				</div>
			)}
		</div>
	);
};

export default AccountPlanCard;
