import { Menu } from '@headlessui/react';
import { AxiosError } from 'axios';
import { OrderStatusColors } from 'components/pages/orders/OrderDetail';
import ProductTable from 'components/pages/orders/components/ProductTable';
import UpdateShippingAddressModal from 'components/pages/orders/components/modals/UpdateShippingAddressModal';
import { DeliveryTypes } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import { GetOrderDetailNewRequest } from 'features/orders/_common/get-order-detail-new/get-order-detail-new.request';
import orderService from 'features/orders/_common/order.service';
import { CreateOrderStatuHistoryRequest } from 'features/orders/derived-features/order-statu-histories/create-order-statu-history/create-order-statu-history.request';
import orderStatuHistoriesService from 'features/orders/derived-features/order-statu-histories/order-statu-histories.service';
import { OrderStatuTypes } from 'features/orders/dtos/order-statu-types';
import dateHelper from 'helpers/dateHelper';
import stringHelper from 'helpers/string.helper';
import toastHelper from 'helpers/toast.helper';
import PrintDropdown from 'helpers/widgets/Order/PrintDropdown/PrintDropdown';
import ShippingInfo from 'helpers/widgets/Order/ShippingInfo';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { OrderDto, OrderTicketDto } from 'models/dtos/order/order';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useRef, useState } from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { BsArrowRight, BsBrush } from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './OrderDetail.module.scss';
import CreateOrderStatuHistoryModal from './modals/CreateOrderStatuHistoryModal';
import { ProductPrices } from 'components/pages/orders/components/products-prices/ProductPrices';

type Props = {
	barcode: string;
	setOrderList: Function;
};

const OrderDetail = (props: Partial<Props>) => {
	const { orderId } = useParams();
	const navigate = useNavigate();

	const statusContainerRef = useRef<HTMLDivElement>(null);

	const [loading, setLoading] = useState(false);
	const [statuLoading, setStatuLoading] = useState<boolean>(false);
	const [changeShippingAddressVisible, setChangeShippingAddressVisible] = useState<boolean>(false);
	const [statuModalVisible, setStatuModalVisible] = useState<boolean>(false);
	const [isDragging, setIsDragging] = useState<boolean>(false);
	const [startX, setStartX] = useState<number>(0);
	const [scrollLeft, setScrollLeft] = useState<number>(0);
	const [scrollWidth, setScrollWidth] = useState<number>(0);
	const [order, setOrder] = useState<OrderDto | null>(null);
	const [selectedStatus, setSelectedStatu] = useState<OrderStatuTypes>(OrderStatuTypes.Unknown);

	const getOrderDetail = async () => {
		try {
			setLoading(true);
			if (!orderId) throw '';

			const response = await orderService.getOrderDetailNew(new GetOrderDetailNewRequest(orderId));
			if (!response.isSuccess) throw '';

			console.clear();

			setOrder(response.data);
		} catch (error) {
			setOrder(null);

			if (error instanceof AxiosError && error.response?.status === 500) navigate(-1);
		} finally {
			setLoading(false);
		}
	};

	const updateOrderStatus = async (request: Partial<CreateOrderStatuHistoryRequest>) => {
		try {
			setStatuLoading(true);

			const response = await orderStatuHistoriesService.create(new CreateOrderStatuHistoryRequest({ ...request }));

			if (!response.isSuccess) throw '';

			await getOrderDetail();
		} catch (error) {
		} finally {
			setStatuLoading(false);
		}
	};

	const orderNoHandler = () => {
		if (order?.etsyOrder?.receiptId) {
			return (
				<div className={styles.headerTitles}>
					<h1
						className="container-header-title cursor-pointer"
						onClick={() => {
							if (!order) return;

							navigator.clipboard.writeText(order?.etsyOrder?.receiptId?.toString() || '');
							toastHelper.success(`Copied '${order?.etsyOrder?.receiptId}' to clipboard`);
						}}>
						#{order?.etsyOrder?.receiptId}
					</h1>
					<h6
						className="cursor-pointer"
						onClick={() => {
							if (!order) return;

							navigator.clipboard.writeText(order?.code);
							toastHelper.success(`Copied '${order?.code}' to clipboard`);
						}}>
						#{order?.code}
					</h6>
				</div>
			);
		} else {
			return (
				<h1
					className="container-header-title cursor-pointer"
					onClick={() => {
						if (!order) return;

						navigator.clipboard.writeText(order?.code);
						toastHelper.success(`Copied '${order?.code}' to clipboard`);
					}}>
					#{order?.code}
				</h1>
			);
		}
	};

	const ticketStatuHandler = (ticket: OrderTicketDto) => {
		if (ticket.isActive) {
			if (ticket.isAnswered) return 'Answered';
			else return 'Waiting';
		} else return 'Closed';
	};

	const statuStyleHandler = (ticket: OrderTicketDto) => {
		if (ticket.isActive) {
			if (!ticket.isAnswered) return styles.isWaitingStatus;
		} else return styles.isClosedStatus;
	};

	useEffect(() => {
		if (!orderId) return;

		getOrderDetail();
	}, [orderId]);

	useEffect(() => {
		if (scrollWidth === 0 || !statusContainerRef.current || statusContainerRef.current.scrollLeft === scrollWidth) return;

		const width = statusContainerRef.current.getBoundingClientRect().width;
		const currentScrollWidth = statusContainerRef.current.scrollWidth;

		if (currentScrollWidth > width) statusContainerRef.current.classList.remove('pointer-events-none');
		else statusContainerRef.current.classList.add('pointer-events-none');

		statusContainerRef.current.scrollLeft = scrollWidth;
	}, [scrollWidth]);

	return (
		<main className="container relative">
			<section className="container-header">
				{order && orderNoHandler()}

				<div className="container-header-tools">
					{window.history.length > 1 && <PrButton text="Go Back" onClick={() => navigate(-1)} type="secondary" icon={<span className="pi pi-fw pi-arrow-left" />} />}
					{!!order && order.statu.type !== OrderStatuTypes.PendingApproval ? <PrintDropdown loading={loading} setLoading={setLoading} orderIds={orderId as string} /> : null}
				</div>
			</section>

			{order?.ticket && (
				<section className="container-body p-3 z-1">
					<div className={styles.ticketsHeader}>
						<div>
							<h4 className="m-0">Ticket Information</h4>
							<p className="text-sm text-600">The ticket information details are provided below.</p>
						</div>
						<Link to={`/ticket/${order.ticket?.ticketId}`} target="_blank">
							<PrButton text="Go Ticket Detail" type="secondary" icon={<span className="pi pi-external-link" />} />
						</Link>
					</div>

					<div className={styles.tickets}>
						<div className={styles.ticketsCard}>
							<h6>Ticket Code</h6>
							<div>{order.ticket.ticketCode}</div>
						</div>

						<div className={styles.ticketsCard}>
							<h6>Title</h6>
							<div>{order.ticket.title}</div>
						</div>

						<div className={styles.ticketsCard}>
							<h6>Created Date</h6>
							<div>{dateHelper.formatDate(order.ticket.createdDate)}</div>
						</div>

						<div className={styles.ticketsCard}>
							<h6>Status</h6>
							<div className={`${styles.ticketsStatus} ${statuStyleHandler(order.ticket)}`}>
								<span>{ticketStatuHandler(order.ticket)}</span>
							</div>
						</div>
					</div>

					<div className={`${styles.ticketsCard} mt-2`}>
						<h6>Last Comment</h6>
						<div>
							<div>
								<span className="font-bold">From: </span>
								{order.ticket.lastCommentOwnerFullName}
							</div>
							<div>
								<span className="font-bold">Date: </span>
								{dateHelper.formatDate(order.ticket.lastCommentDate)}
							</div>
						</div>
						<span dangerouslySetInnerHTML={{ __html: order.ticket.lastComment }} className="mt-2" />
					</div>
				</section>
			)}

			<section className="container-body p-3 z-4">
				<h4 className="m-0">Details</h4>
				<p className="text-sm text-600">The order details are provided below.</p>

				<div className={styles.sellerDetails}>
					<h6>Seller Details</h6>
					<div className={styles.sellerInfo}>
						<span>Name: </span>
						<span>{order?.sellerFullName}</span>
					</div>
					<div className={styles.sellerInfo}>
						<span>Store: </span>
						<span>{order?.storeName}</span>
					</div>
				</div>

				<div className={styles.detailWrapper}>
					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Customer Name</h6>
						</div>

						<p>{order?.customerFullName || '-'}</p>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Customer Note</h6>
						</div>

						<p>{order?.customerNote || '-'}</p>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Gift Note</h6>
						</div>

						<p>{order?.giftInformation?.message || '-'}</p>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Order Note</h6>
						</div>

						<p>{order?.sellerNote || '-'}</p>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Delivery Type / Order Type</h6>
						</div>

						<span>{DeliveryTypes[order?.deliveryType || 0]} / </span>
						<span>{order !== null ? (order?.isManuelOrder ? 'Manuel' : order?.isExternalOrder ? 'External' : 'Automatic') : '-'}</span>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Platform</h6>
						</div>

						<p>{order !== null ? (order?.isManuelOrder ? 'Printram' : 'ETSY') : '-'}</p>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Shipping Address</h6>

							{order?.statu.type === OrderStatuTypes.PendingApproval && order.deliveryType === DeliveryTypes.Shipment ? <PrButton icon={<BsBrush />} type="icon" id="shippingAddressUpdate" tooltip="Update shipping address" onClick={() => setChangeShippingAddressVisible(true)} className="text-primary" /> : null}
						</div>

						<address>{order?.orderAddress?.formattedAddress || '-'}</address>
					</div>

					<div className={styles.detailItem}>
						<div className={styles.detailItemHeader}>
							<h6>Status</h6>
						</div>

						<Menu as="div" className="pr-dropdown w-full">
							<Menu.Button className={`pr-btn pr-btn-secondary w-full${!order || !order.availableOrderStatus || (!!order && order.availableOrderStatus && order.availableOrderStatus.length < 1) ? ' disabled' : ''}`}>
								<span className="mr-2">{stringHelper.parseAtUpperCaseAndJoin(order?.statu.readableName || '')}</span>
								<AiFillCaretDown className="ml-auto" />
							</Menu.Button>

							<Menu.Items className="pr-dropdown-menu right w-full">
								{!!order?.availableOrderStatus &&
									order.availableOrderStatus.map((_statu) => (
										<Menu.Item
											key={_statu.id}
											as="button"
											onClick={() => {
												if (_statu.type === OrderStatuTypes.Canceled || _statu.type === OrderStatuTypes.UrgentOrder) {
													setSelectedStatu(_statu.type);
													setStatuModalVisible(true);
													return;
												}

												updateOrderStatus({ orderId, orderStatuId: _statu.type });
											}}
											className="pr-btn pr-dropdown-menu-item">
											{_statu.readableName}
										</Menu.Item>
									))}
							</Menu.Items>
						</Menu>
					</div>
				</div>
			</section>

			<section className="container-body p-3 z-3">
				<h4 className="m-0">Status History</h4>
				<p className="text-sm text-600">The order status history details are provided below.</p>

				{!!order ? (
					<div
						ref={(_ref) => {
							if (!_ref) return;

							setScrollWidth(_ref.scrollWidth);

							return ((statusContainerRef.current as any) = _ref);
						}}
						className={`${styles.detailWrapper} ${styles.scrollable}`}
						onMouseUp={() => setIsDragging(false)}
						onMouseDown={(event) => {
							if (!statusContainerRef.current) return;

							setIsDragging(true);

							setStartX(event.pageX - statusContainerRef.current.offsetLeft);
							setScrollLeft(statusContainerRef.current.scrollLeft);
						}}
						onMouseLeave={() => setIsDragging(false)}
						onMouseMove={(event) => {
							if (!isDragging || !statusContainerRef.current) return;

							const x = event.pageX - statusContainerRef.current.offsetLeft;
							const walk = x - startX;
							statusContainerRef.current.scrollLeft = scrollLeft - walk;
						}}>
						{order?.statuHistories.map((_history, _index) => (
							<div key={_index} className="flex align-items-center justify-content-center gap-1 w-full">
								<div className={`${styles.detailItem} w-full h-full`}>
									<div className={styles.detailItemHeader} style={{ color: OrderStatusColors.find((statu) => statu.orderStatu === (_history.statu.type as any as OrderStatuTypes))?.color }}>
										<h6>{stringHelper.parseAtUpperCaseAndJoin(_history.statu.readableName)}</h6>
									</div>

									<p className="flex flex-column">
										<span className="mt-auto">{dateHelper.formatDate(_history.createdDate)}</span>

										{!!_history.reason ? <span>{_history.reason}</span> : null}
									</p>
								</div>

								{order.statuHistories.length - 1 !== _index ? <BsArrowRight /> : null}
							</div>
						))}
					</div>
				) : null}
			</section>

			<section className="container-body p-3 z-2">
				<h4 className="m-0">Products</h4>
				<p className="text-sm text-600">Your order's products are displayed in the table below.</p>

				{!!order ? <ProductTable orderItems={order.items} /> : null}
			</section>

			{!!order ? <ProductPrices prices={order.prices} /> : null}

			<section className="container-body p-3 z-1">
				<h4 className="m-0">Shipping Information</h4>
				<p className="text-sm text-600">The order shipping information details are provided below.</p>

				{!!order && !!order.transport ? (
					<ShippingInfo {...order.transport} />
				) : (
					<div className="h-7rem flex align-items-center justify-content-center" style={{ backgroundColor: '#fafafa', border: '1px solid #eaeaea', borderRadius: '0.2rem' }}>
						<h5 className="m-0 text-color-secondary flex flex-wrap align-items-center justify-content-center gap-3">
							{!!order && order.statu.type === OrderStatuTypes.PendingApproval
								? "In order to view the transport information, the order's status needs to be changed to 'Order Approved'."
								: !!order?.orderAttachmentFiles && order.orderAttachmentFiles.length > 0
								? order.orderAttachmentFiles.map((_attachment, index) => (
										<a key={index} href={_attachment.filePath} target="_blank" className="text-primary">
											{index + 1}. Open label
										</a>
								  ))
								: 'Shipping Information not found'}
						</h5>
					</div>
				)}
			</section>

			{!!order ? <UpdateShippingAddressModal order={order} visible={changeShippingAddressVisible} setVisible={setChangeShippingAddressVisible} callback={getOrderDetail} /> : null}
			<CreateOrderStatuHistoryModal data={{ statu: selectedStatus, orderId: orderId || '' }} visible={statuModalVisible} setVisible={setStatuModalVisible} callback={updateOrderStatus} />

			{loading || statuLoading ? (
				<div className="pr-loading-sticky">
					<div className="pr-spinner-wrapper">
						<ProgressSpinner className="p-progress-color" strokeWidth="4" />
					</div>
				</div>
			) : null}
		</main>
	);
};

export default OrderDetail;
