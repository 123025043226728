import { CreateOrderContext, CreateOrderContextType, StepWithComponentEnum, orderSteps } from 'components/pages/orders/create-order/context/CreateOrderContextPrvider';
import countryService from 'features/countries/country.service';
import { GetCountriesRequest } from 'features/countries/get-countries/get-countries.request';
import { CountryBaseDto } from 'features/countries/get-countries/get-countries.response';
import { CalculateItems, CalculateOrderPricesWithVariantsAndSidesRequest } from 'features/orders/_common/calculate-order-prices-with-variants-and-sides/calculate-order-prices-with-variants-and-sides.request';
import { CreateManuelOrderGiftInformationRequestDto, CreateManuelOrderRequest, DeliveryTypes, prefixOptions } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import orderService from 'features/orders/_common/order.service';
import { GetShippingRatesRequest } from 'features/orders/derived-features/order-transports/get-shipping-rates/get-shipping-rates.request';
import { orderTransportService } from 'features/orders/derived-features/order-transports/order-transport.service';
import { ErrorMessage, Form, Formik } from 'formik';
import stringHelper from 'helpers/string.helper';
import toastHelper from 'helpers/toast.helper';
import PrDropdown from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import SelectButtonForm from 'helpers/widgets/Printram/Forms/Buttons/SelectButton';
import AutoCompleteInput from 'helpers/widgets/Printram/Forms/Input/AutoCompleteInput';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import PrTextareaInput from 'helpers/widgets/Printram/Forms/Input/PrTextareaInput';
import React, { useEffect, useState } from 'react';
import { getPrintramStores } from 'redux/features/printram-store/printramStoreSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as Yup from 'yup';
import styles from './ShipForm.module.scss';
import { FiPlus } from 'react-icons/fi';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import FormatAddressDialog from './dialogs/FormatAddressDialog';
import camelCaseHelper from '../../../../../../../../helpers/camel-case.helper';
import { ValidateAddressDTO, ValidateAddressResponseDataDTO } from '../../../../../../../../features/orders/derived-features/order-transports/validate-address/validate-address-response';
import { ValidateAddressRequest, ValidationAddressDto } from '../../../../../../../../features/orders/derived-features/order-transports/validate-address/validate-address-request';

export const ShipForm = () => {
	const stores = useAppSelector((_state) => _state.printramStore.data || []);
	const dispatch = useAppDispatch();

	const context = React.useContext(CreateOrderContext) as CreateOrderContextType;

	const [selectedAttachmentFile, setSelectedAttachmentFile] = useState<File | undefined>(undefined);
	const [countries, setCountries] = useState<CountryBaseDto[]>([]);
	const [formatAddressVisible, setFormatAddressVisible] = useState<boolean>(false);
	const [orderRequest, setOrderRequest] = useState<CreateManuelOrderRequest>();
	const [validateAddressResponse, setValidateAddressResponse] = useState<ValidateAddressResponseDataDTO | undefined>(undefined);
	const [tutorialStepsEnabled, setTutorialStepsEnabled] = useState<boolean>(false);
	const steps = [
		{
			element: '.tutorial-selector1',
			intro: 'Enter the information of the person the product will be sent to in this section.',
			position: 'bottom'
		},
		{
			element: '.tutorial-selector2',
			intro: 'If you want to create an order for pickup or upload your own shipping label, you can select "PickUp."'
		},
		{
			element: '.tutorial-selector3',
			intro: 'Enter the address information of the person the product will be sent to in this section.',
			position: 'left'
		}
	];

	const getCountries = async () => {
		try {
			const response = await countryService.getCountries(new GetCountriesRequest());

			if (!response.isSuccess || !response.data) throw '';

			setCountries(response.data);
		} catch (error) {
			setCountries([]);
		}
	};

	const validateAddress = async (values: CreateManuelOrderRequest) => {
		context.setLoading(true);
		const request: CreateManuelOrderRequest = structuredClone(values);
		try {
			if (request.deliveryType !== DeliveryTypes.PickUp) {
				const validationAddress = new ValidationAddressDto({
					receiverFullName: values.customerFullName,
					receiverPhoneNumber: values.deliveryAddress!.receiverPhoneNumber || '',
					receiverEmail: values.deliveryAddress!.receiverEmail,
					firstLine: values.deliveryAddress!.firstLine,
					city: values.deliveryAddress!.city,
					state: values.deliveryAddress!.state,
					zip: values.deliveryAddress!.zip,
					countryIso: values.deliveryAddress!.countryIso,
					formattedAddress: values.deliveryAddress!.formattedAddress,
					vatNumber: values.deliveryAddress!.vatNumber || ''
				});

				const validateRequest: ValidateAddressRequest = new ValidateAddressRequest({
					shipTo: validationAddress
				});
				const validateResponse = await orderTransportService.validateAddress(validateRequest);

				if (!validateResponse.isSuccess) throw '';

				const data = camelCaseHelper.toCamelCase(validateResponse.data);

				if (!data.isValidationExist) {
					submitForm(request);
					return;
				}

				setValidateAddressResponse(data.response!);
				setOrderRequest(request);
				setFormatAddressVisible(true);
				return;
			}

			submitForm(request);
			return;
		} catch (error) {
		} finally {
		}
	};

	const onSubmitDialog = (address: ValidateAddressDTO) => {
		let newRequest = structuredClone(orderRequest);

		if (!newRequest || !newRequest.deliveryAddress) return;

		newRequest.deliveryAddress.firstLine = address.addressLine1;
		newRequest.deliveryAddress.city = address.cityLocality;
		newRequest.deliveryAddress.formattedAddress = address.completeAddress || newRequest.deliveryAddress.formattedAddress;
		newRequest.deliveryAddress.countryIso = address.countryCode;
		newRequest.deliveryAddress.zip = address.postalCode;
		newRequest.deliveryAddress.state = address.stateProvince;

		setFormatAddressVisible(false);

		submitForm(newRequest);
	};

	const submitForm = async (request: CreateManuelOrderRequest) => {
		try {
			context.setLoading(true);

			const variantAndQuantities: { [id: string]: number } = {};

			for (const item of request.items) {
				if (!!variantAndQuantities[item.productVariantId]) {
					variantAndQuantities[item.productVariantId] += item.quantity;

					continue;
				}

				variantAndQuantities[item.productVariantId] = item.quantity;
			}

			if (request.deliveryType !== DeliveryTypes.PickUp) {
				const shipRateRequest = new GetShippingRatesRequest({
					shipTo: structuredClone(request.deliveryAddress) || undefined,
					variantAndQuantities
				});

				if (!!shipRateRequest.shipTo.receiverPhoneNumber) shipRateRequest.shipTo.receiverPhoneNumber = shipRateRequest.shipTo.phonePrefix.value + shipRateRequest.shipTo.receiverPhoneNumber;
				else shipRateRequest.shipTo.receiverPhoneNumber = null;

				const response = await orderTransportService.getShippingRates(shipRateRequest);

				if (!response.data || !response.isSuccess) throw '';

				const minPriceRate = Math.min(...response.data.map((_rate) => +_rate.amount));

				const minRate = response.data.find((_rate) => +_rate.amount === minPriceRate);

				const rateRequest = new CalculateOrderPricesWithVariantsAndSidesRequest({
					shippingRate: minRate,
					shipTo: structuredClone(request.deliveryAddress),
					items: request.items.map(
						(_item) =>
							({
								uniqueIdentifier: _item.uniqueId,
								quantity: _item.quantity,
								variantId: _item.productVariantId,
								sideIdentities: _item.sides.map((_side) => _side.productPrintSideId)
							} as CalculateItems)
					)
				});

				if (!!rateRequest?.shipTo?.receiverPhoneNumber) rateRequest.shipTo.receiverPhoneNumber = rateRequest.shipTo.phonePrefix.value + rateRequest.shipTo.receiverPhoneNumber;
				if (rateRequest.shipTo?.receiverPhoneNumber === '') rateRequest.shipTo.receiverPhoneNumber = null;

				const rateResponse = await orderService.calculateOrderPricesWithVariantsAndSidesResponse(rateRequest);
				if (!rateResponse.isSuccess || !rateResponse.data) throw '';

				context.setShippingRates(response.data);

				context.setSelectedRate(minRate);

				context.setCalculatedPrice(rateResponse.data);
			}

			context.setIsShowShippingForm(false);

			context.setIsNewShippingRatesCalculateRequired(false);
		} catch (error) {
			if (typeof error === 'string') toastHelper.warning(error);
		} finally {
			context.setRequest(request);
			context.setLoading(false);

			if (request.deliveryType === DeliveryTypes.PickUp) context.setOrderStep(orderSteps.find((_step) => _step.value === StepWithComponentEnum.ReviewOrder)!);
		}
	};

	useEffect(() => {
		getCountries();

		dispatch(getPrintramStores(context.request.userId));
		setTutorialStepsEnabled(true);
	}, []);

	useEffect(() => {
		if (!stores || context.request.storeId) return;

		if (stores.length === 0) return;

		context.setRequest((_current) => {
			const newRequest = structuredClone(_current);

			newRequest.storeId = stores[0].storeId;

			return newRequest;
		});
	}, [stores]);

	return (
		<React.Fragment>
			<Formik
				initialValues={context.request}
				enableReinitialize
				validationSchema={Yup.object().shape({
					deliveryType: Yup.number().required(),
					storeId: Yup.string().required('Select a printram store'),
					customerFullName: Yup.string()
						.required('Full Name is required')
						.test('is-full-name', 'Enter your Full Name', (value) => {
							if (!value) return false;

							return value.trim().split(' ').length > 1;
						}),
					deliveryAddress: Yup.object().when('deliveryType', {
						is: DeliveryTypes.Shipment,
						then: Yup.object().shape({
							receiverEmail: Yup.string().email('Email address must be valid.').required('Email is required').min(5, 'Email must be at least 5 characters.').max(75, 'Email must be a maximum of 75 characters.'),
							formattedAddress: Yup.string().required('Search adress is required'),
							countryIso: Yup.string().required('Select a country'),
							state: Yup.string().required('State is required'),
							firstLine: Yup.string().required('Address Line 1 is required'),
							city: Yup.string().required('City is required'),
							zip: Yup.string().required('Zip Code is required')
						})
					})
				})}
				onSubmit={async (values) => validateAddress(values)}>
				{(form) => (
					<Form>
						<div className="container-body p-0 flex mx-auto" style={{ width: form.values.deliveryType === DeliveryTypes.Shipment || (form.values.deliveryType === DeliveryTypes.PickUp && !!form.values.attachmentFiles && form.values.attachmentFiles.length > 0 && !!selectedAttachmentFile) ? '100%' : '50%' }}>
							{/* <Steps
								enabled={tutorialStepsEnabled}
								steps={steps}
								initialStep={0}
								onExit={() => setTutorialStepsEnabled(false)}
								onComplete={() => setTutorialStepsEnabled(false)}
								options={{
									showBullets: false,
									dontShowAgain: true,
									dontShowAgainCookie: 'ship-form-intro',
									disableInteraction: false
								}}
							/> */}
							<div className="flex-1 p-5 tutorial-selector1">
								<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
									Recipient
								</h2>

								<div>
									<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
										Printram Store *
									</label>
									<PrDropdown
										options={stores.map((_store) => ({
											label: _store.name,
											value: _store.storeId
										}))}
										value={stores
											.map((_store) => ({
												label: _store.name,
												value: _store.storeId
											}))
											.find((_store) => _store.value === form.values.storeId)}
										placeholder="Select store"
										className="w-full"
										onChange={(event) => {
											form.setFieldValue('storeId', event.value);
										}}
									/>
									<ErrorMessage name="storeId" component="small" className="text-red font-medium" />
								</div>

								<div className="mt-4 tutorial-selector2">
									<SelectButtonForm
										label="Delivery Type"
										options={[
											{
												label: DeliveryTypes[DeliveryTypes.Shipment],
												value: DeliveryTypes.Shipment
											},
											{ label: DeliveryTypes[DeliveryTypes.PickUp], value: DeliveryTypes.PickUp }
										]}
										value={form.values.deliveryType}
										onChange={(event) => !!event.value && form.setFieldValue('deliveryType', event.value)}
									/>
								</div>

								<div className="mt-4">
									<PrTextInput
										type="text"
										label="Full Name *"
										style={{ height: '2.375rem' }}
										value={form.values.customerFullName}
										onChange={(e) => {
											const formattedName = stringHelper.formatName(e.target.value);

											form.setFieldValue('customerFullName', formattedName);
											form.setFieldValue('deliveryAddress.receiverFullName', formattedName);
										}}
									/>

									<ErrorMessage name="customerFullName" component="small" className="text-red font-medium" />
								</div>

								{form.values.deliveryType !== DeliveryTypes.PickUp ? (
									<div className="mt-4">
										<PrTextInput
											type="email"
											label="Email *"
											value={form.values.deliveryAddress?.receiverEmail}
											style={{ height: '2.375rem' }}
											onChange={(e) => {
												form.setFieldValue('deliveryAddress.receiverEmail', e.target.value || '');
											}}
										/>

										<ErrorMessage name="deliveryAddress.receiverEmail" component="small" className="text-red font-medium" />
									</div>
								) : null}

								{form.values.deliveryType !== DeliveryTypes.PickUp ? (
									<React.Fragment>
										<label className="mt-4 block" style={{ fontSize: '19px', fontWeight: '600' }}>
											Phone <span className="pr-input-label-optional">(Optional)</span>
										</label>

										<div className="flex gap-2 align-items-end mt-1">
											<div>
												<PrDropdown
													options={prefixOptions}
													value={form.values.deliveryAddress?.phonePrefix}
													className="w-7rem"
													onChange={(e) => {
														form.setFieldValue('deliveryAddress.phonePrefix', e);
													}}
												/>
											</div>

											<PrTextInput
												name="phone"
												type="tel"
												value={form.values.deliveryAddress?.receiverPhoneNumber || ''}
												className="w-full"
												style={{ height: '2.375rem' }}
												onChange={(e) => {
													if (e.target.value.length > 10) return;

													if (e.target.value.length > 0 && !/^\d+$/.test(e.target.value)) return;

													form.setFieldValue('deliveryAddress.receiverPhoneNumber', e.target.value);
												}}
											/>
										</div>
									</React.Fragment>
								) : null}

								<div className="mt-4">
									<PrTextareaInput
										label="Customer Note"
										value={form.values.customerNote || ''}
										rows={2}
										required={false}
										onChange={(e) => {
											form.setFieldValue('customerNote', e.target.value || null);
										}}
									/>
								</div>

								<div className="mt-4">
									<PrTextareaInput
										label="Seller Note"
										value={form.values.sellerNote || ''}
										rows={2}
										required={false}
										onChange={(e) => {
											form.setFieldValue('sellerNote', e.target.value || null);
										}}
									/>
								</div>

								<div className="mt-4">
									<PrTextareaInput
										label="Gift Message"
										value={form.values.giftInformation?.message}
										rows={2}
										required={false}
										onChange={(e) => {
											const value = e.target.value;

											if (!form.values.giftInformation) {
												form.setFieldValue('giftInformation', new CreateManuelOrderGiftInformationRequestDto({ message: e.target.value }));
												return;
											}

											form.setFieldValue('giftInformation.message', value);
										}}
									/>
								</div>

								{form.values.deliveryType === DeliveryTypes.PickUp ? (
									<React.Fragment>
										<div className="mt-4">
											<h5 style={{ fontSize: '19px', fontWeight: '600' }}>
												Ship Labels <span className="pr-input-label-optional">(Optional)</span>
											</h5>

											<label htmlFor="printFile" className={styles.printFileWrapper}>
												<input
													type="file"
													value={''}
													onChange={(e) => {
														if (!e.target.files) return;

														const files = e.target.files as any as File[];

														const attachmentFiles = !!form.values.attachmentFiles ? [...form.values.attachmentFiles] : [];

														for (const file of files) {
															if (!file.name.endsWith('.pdf')) continue;
															if (attachmentFiles.findIndex((_attachmentFile) => _attachmentFile.name === file.name) > -1) continue;

															attachmentFiles.push(file);
														}

														form.setFieldValue('attachmentFiles', attachmentFiles);
													}}
													multiple
													accept=".pdf"
													name="printFile"
													id="printFile"
													className="absolute top-0 left-0 right-0 bottom-0 opacity-0 cursor-pointer"
												/>

												<div role="button" tabIndex={0} className={styles.printFileBtn}>
													<FiPlus size="1.808rem" />
													<span>Ship Label File</span>
												</div>

												<div className={styles.printFileDivider} />

												<div className={styles.printFileInfo}>
													<span className={styles.printTitle}>Drag and Drop "Ship Labels" Here</span>
													<span className={styles.printAccepts}>You can upload: .pdf, Files</span>
												</div>
											</label>
										</div>

										{!!form.values.attachmentFiles ? (
											<div className="mt-4 flex flex-column gap-3">
												{form.values.attachmentFiles.map((_file, fileIndex) => (
													<div
														key={fileIndex}
														className={`flex justify-content-between h-3rem border-1 cursor-pointer border-round-lg select-none${selectedAttachmentFile?.name === _file.name ? ' surface-100 border-600' : ' border-400'}`}
														onClick={() => {
															setSelectedAttachmentFile(_file.name === selectedAttachmentFile?.name ? undefined : _file);
														}}>
														<span className="h-full flex align-items-center px-3">{_file.name}</span>

														<span
															role="button"
															tabIndex={0}
															className="cursor-pointer px-3 border-left-1 h-full flex align-items-center border-400"
															onClick={(e) => {
																e.stopPropagation();

																setSelectedAttachmentFile(undefined);

																const attachmentFiles = form.values.attachmentFiles?.filter((_attachmentFile) => _attachmentFile.name !== _file.name);

																form.setFieldValue('attachmentFiles', !!attachmentFiles && attachmentFiles.length > 0 ? attachmentFiles : null);
															}}>
															<i className="pi pi-times"></i>
														</span>
													</div>
												))}
											</div>
										) : null}
									</React.Fragment>
								) : null}
							</div>

							{form.values.deliveryType === DeliveryTypes.PickUp ? (
								!!form.values.attachmentFiles && form.values.attachmentFiles.length > 0 && !!selectedAttachmentFile ? (
									<div className="flex-1 border-left-1 border-400 p-5">
										<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
											Label Preview
										</h2>

										<object data={`${URL.createObjectURL(selectedAttachmentFile)}#toolbar=0&view=Fit`} type="application/pdf" className="w-full h-full" style={{ maxHeight: 'calc(100% - 4rem)' }}>
											<div>No online PDF viewer installed</div>
										</object>
										{/* <iframe src={`${URL.createObjectURL(selectedAttachmentFile)}#toolbar=0&view=Fit`} className="w-full h-full" style={{ maxHeight: 'calc(100% - 4rem)' }} /> */}
										{/* <div className="border-1 border-dashed border-400 h-full border-round-sm surface-50" style={{ maxHeight: 'calc(100% - 4rem)' }}></div> */}
									</div>
								) : null
							) : (
								<div className="flex-1 border-left-1 border-400 p-5 tutorial-selector3">
									<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
										Shipping address
									</h2>

									{countries.length > 0 ? (
										<div className="mt-4">
											<AutoCompleteInput
												label="Search Address *"
												value={form.values.deliveryAddress?.formattedAddress}
												defaultValue={!!form.values.deliveryAddress && form.values.deliveryAddress.formattedAddress.length > 1 ? form.values.deliveryAddress.formattedAddress : ''}
												style={{ height: '2.375rem' }}
												onChange={(e) => form.setFieldValue('deliveryAddress.formattedAddress', e.target.value)}
												onPlaceSelectedDto={(e) => {
													form.setFieldValue('deliveryAddress.formattedAddress', e.formattedAddress);
													form.setFieldValue('deliveryAddress.state', e.state);
													form.setFieldValue('deliveryAddress.city', e.city);
													form.setFieldValue('deliveryAddress.zip', e.zip);
													form.setFieldValue('deliveryAddress.firstLine', e.firstLine);
													form.setFieldValue('deliveryAddress.secondLine', e.secondLine);

													for (const country of countries) {
														if (country.iso2 !== e.countryIso2) continue;

														form.setFieldValue('deliveryAddress.countryIso', country.iso2);
														break;
													}
												}}
											/>

											<ErrorMessage name="deliveryAddress.formattedAddress" component="small" className="text-red font-medium" />
										</div>
									) : null}

									<div className="mt-4 flex gap-4">
										<div className="w-full">
											<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
												Country *
											</label>

											<div>
												<PrDropdown
													value={countries
														.map((_country) => ({
															label: _country.name,
															value: _country.iso2
														}))
														.find((_country) => _country.value === form.values.deliveryAddress?.countryIso)}
													options={countries.map((_country) => ({
														label: _country.name,
														value: _country.iso2
													}))}
													className="w-full"
													onChange={(e) => {
														form.setFieldValue('deliveryAddress.countryIso', e.value);
													}}
												/>

												<ErrorMessage name="deliveryAddress.countryIso" component="small" className="text-red font-medium" />
											</div>
										</div>

										<div className="w-full">
											<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
												State *
											</label>

											<PrTextInput
												value={form.values.deliveryAddress?.state || ''}
												className="w-full"
												style={{ height: '2.375rem' }}
												onChange={(e) => {
													form.setFieldValue('deliveryAddress.state', e.target.value || '');
												}}
											/>

											<ErrorMessage name="deliveryAddress.state" component="small" className="text-red font-medium" />
										</div>
									</div>

									<div className="mt-4">
										<PrTextInput
											label="Address Line 1 *"
											value={form.values.deliveryAddress?.firstLine || ''}
											style={{ height: '2.375rem' }}
											onChange={(e) => {
												form.setFieldValue('deliveryAddress.firstLine', e.target.value || '');
											}}
										/>

										<ErrorMessage name="deliveryAddress.firstLine" component="small" className="text-red font-medium" />
									</div>

									<div className="mt-4">
										<PrTextInput
											label="Address Line 2"
											required={false}
											value={form.values.deliveryAddress?.secondLine || ''}
											style={{ height: '2.375rem' }}
											onChange={(e) => {
												form.setFieldValue('deliveryAddress.secondLine', e.target.value || null);
											}}
										/>
									</div>

									<div className="mt-4 flex gap-4">
										<div className="w-full">
											<PrTextInput
												label="City *"
												value={form.values.deliveryAddress?.city || ''}
												style={{ height: '2.375rem' }}
												onChange={(e) => {
													form.setFieldValue('deliveryAddress.city', e.target.value || '');
												}}
											/>

											<ErrorMessage name="deliveryAddress.city" component="small" className="text-red font-medium" />
										</div>

										<div className="w-full">
											<PrTextInput
												label="Zip Code *"
												value={form.values.deliveryAddress?.zip || ''}
												style={{ height: '2.375rem' }}
												onChange={(e) => {
													form.setFieldValue('deliveryAddress.zip', e.target.value || '');
												}}
											/>

											<ErrorMessage name="deliveryAddress.zip" component="small" className="text-red font-medium" />
										</div>
									</div>

									<div className="mt-4">
										<PrTextInput
											label="VAT number"
											required={false}
											value={form.values.deliveryAddress?.vatNumber || ''}
											style={{ height: '2.375rem' }}
											onChange={(e) => {
												form.setFieldValue('deliveryAddress.vatNumber', e.target.value || '');
											}}
										/>
									</div>
								</div>
							)}
						</div>

						<div className="container-body p-3 h-6rem flex align-items-center justify-content-center">
							<PrButton text={context.request.deliveryType === DeliveryTypes.PickUp ? 'Next' : !!context.calculatedPrice ? 'Update & Calculate Shipping' : 'Calculate Shipping'} btnType="submit" />
						</div>
					</Form>
				)}
			</Formik>
			<FormatAddressDialog
				visible={formatAddressVisible}
				validateAddressResponse={validateAddressResponse}
				onSubmit={onSubmitDialog}
				onHide={() => {
					context.setLoading(false);
					setFormatAddressVisible(false);
				}}
			/>
		</React.Fragment>
	);
};
