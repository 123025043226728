import { PaginationDto } from 'features/_common/dtos/paginations/pagination.dto';
import { GetProductsForListRequest } from 'features/products/derived-features/merchant-products/get-products-for-list/get-products-for-list.request';
import { GetProductsForListResponse } from 'features/products/derived-features/merchant-products/get-products-for-list/get-products-for-list.response';
import merchantProductsService from 'features/products/derived-features/merchant-products/merchant-products.service';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { setForProductCatalogPaginationInfo } from 'redux/features/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styles from './ProductCatalogProducts.module.scss';
import { amazonS3Helper } from 'helpers/amazon-s3.helper';
import { Pagination } from 'models/_commons/responses/pagination';
import { Paginator } from 'primereact/paginator';
import { PaginationOrderByEnum } from 'features/_common/dtos/paginations/pagination.interface';
import currencyHelper from 'helpers/curreny.helper';
import { PrLoading } from 'helpers/widgets/Loading/PrLoading';

export const ProductCatalogProducts = () => {
	const { categoryId } = useParams();
	const fromStatePagination = useAppSelector((_state) => _state.pagination.forProductCatalogPaginationInfo as PaginationDto);
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);
	const [products, setProducts] = useState<GetProductsForListResponse[]>([]);
	const [paginationResponse, setPaginationResponse] = useState<Pagination | undefined>(undefined);

	const getProducts = async (pagination: PaginationDto) => {
		try {
			setLoading(true);

			const response = await merchantProductsService.getProductsForList(
				new GetProductsForListRequest({
					categoryId,
					pagination
				})
			);

			if (!response.isSuccess || !response.data) throw '';

			setProducts(response.data);
			setPaginationResponse(response.pagination);

			dispatch(setForProductCatalogPaginationInfo(JSON.parse(JSON.stringify(pagination))));
		} catch (error) {
			setProducts([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getProducts(new PaginationDto(1, 6));
	}, [categoryId]);

	return (
		<div className="mt-3">
			<section className={styles.productWrapper}>
				{products.map((_product) => (
					<Link key={_product.id} to={`/product/detail/${_product.id}`} className={`container-body p-0 mt-0 ${styles.productItem}`}>
						<div className={styles.productItemImgWrapper}>
							<img src={amazonS3Helper.showFile(_product.media, { resize: '250x250' })} loading="lazy" alt={_product.name} />
						</div>

						<div className={styles.productItemBody}>
							<h5 className="text-base">{_product.name.replaceAll(` - ${_product.brand.trim()} ${_product.model.trim()}`, '')}</h5>

							<p className="text-sm text-color-secondary font-semibold">
								{_product.brand} {_product.model}
							</p>

							<p className="text-sm text-color-secondary font-semibold mt-auto">Categories &#x2022; {(_product.categories as string[]).join(', ')}</p>

							<p className="text-sm text-color-secondary font-semibold">
								{_product.colorCount} Colors &#x2022; {_product.sizeCount} Sizes
							</p>

							<p className="font-semibold mt-2">
								From {currencyHelper.formatPrice(_product.minPrice.formattedPricePerUnit)} - {currencyHelper.formatPrice(_product.maxPrice.formattedPricePerUnit)}
							</p>

							{_product.minDiscountedPrice.price.formattedPricePerUnit === 0 || _product.maxDiscountedPrice.price.formattedPricePerUnit === 0 ? null : (
								<p className="text-primary text-xs font-semibold">
									From {currencyHelper.formatPrice(_product.minDiscountedPrice.price.formattedPricePerUnit)} - {currencyHelper.formatPrice(_product.maxDiscountedPrice.price.formattedPricePerUnit)} {_product.minDiscountedPrice.planName} Plan
								</p>
							)}
						</div>
					</Link>
				))}
			</section>

			{!!paginationResponse && paginationResponse.totalItemCount > 0 && products.length > 0 ? (
				<section className={`${styles.ticketsTableBodyItemWrapper} ${styles.loadMore}`}>
					<Paginator
						className="mt-3"
						template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						currentPageReportTemplate={`${!!paginationResponse ? 'Showing {first} to {last} of {totalRecords}' : ''}`}
						first={fromStatePagination.first}
						rows={fromStatePagination.itemCount || 6}
						totalRecords={paginationResponse.totalItemCount}
						rowsPerPageOptions={[3, 6, 9, 12]}
						onPageChange={(event) => {
							getProducts({
								first: event.first,
								itemCount: event.rows,
								pageNumber: event.page + 1,
								orderBy: PaginationOrderByEnum.Descending
							});
						}}
					/>
				</section>
			) : null}

			<PrLoading loading={loading} />
		</div>
	);
};
