import HttpBaseService from 'services/_common/http-base-service';
import { GetExternalOrderByIdRequest } from './get-external-order-by-id/get-external-order-by-id.request';
import { GetExternalOrderByIdResponse } from './get-external-order-by-id/get-external-order-by-id.response';
import { GetOrdersForAdminRequest } from './get-orders-for-admin/get-orders-for-admin.request';
import { GetOrderDetailByBarcodeRequest } from './get-order-detail-by-barcode/get-order-detail-by-barcode.request';
import { GetOrderDetailRequest } from './get-order-detail/get-order-detail.request';
import { GetOrderDetailResponse } from './get-order-detail/get-order-detail.response';
import { GetOrdersForAdminResponse } from './get-orders-for-admin/get-orders-for-admin.response';
import { GetOrderDetailByBarcodeResponse } from './get-order-detail-by-barcode/get-order-detail-by-barcode.response';
import { GetOrdersBySellerIdRequest } from './get-orders-by-seller-id/get-orders-by-seller-id.request';
import { GetOrdersBySellerIdResponse } from './get-orders-by-seller-id/get-orders-by-seller-id.response';

//#region Generate PDF imports
import { GenerateOrderDetailPdfsRequest } from './pdfs/generate-order-detail-pdfs/generate-order-detail-pdfs.request';
import { GenerateOrderDetailPdfsResponse } from './pdfs/generate-order-detail-pdfs/generate-order-detail-pdfs.response';
import { GenerateOrderItemBarcodePdfsRequest } from './pdfs/generate-order-item-barcode-pdfs/generate-order-item-barcode-pdfs.request';
import { GenerateOrderItemBarcodePdfsResponse } from './pdfs/generate-order-item-barcode-pdfs/generate-order-item-barcode-pdfs.response';
import { GenerateOrderReceiptPdfsRequest } from './pdfs/generate-order-receipt-pdfs/generate-order-receipt-pdfs.request';
import { GenerateOrderReceiptPdfsResponse } from './pdfs/generate-order-receipt-pdfs/generate-order-receipt-pdfs.response';
import { GenerateOrderPdfsRequest } from './pdfs/generate-order-pdfs/generate-order-pdfs.request';
import { GenerateOrderPdfsResponse } from './pdfs/generate-order-pdfs/generate-order-pdfs.response';
import { GetOrderDesignArchiveRequest } from './get-order-design-archive/get-order-design-archive.request';
import { GetOrderDesignArchiveResponse } from './get-order-design-archive/get-order-design-archive.response';
//#endregion

import { GetOrdersForPrintCenterRequest } from './get-orders-for-print-center/get-orders-for-print-center.request';
import { GetOrdersForPrintCenterResponse } from './get-orders-for-print-center/get-orders-for-print-center.response';
import { CreateManuelOrderRequest } from './create-manuel-order/create-manuel-order.request';
import { CreateManuelOrderResponse } from './create-manuel-order/create-manuel-order.response';
import { GetOrderDetailNewRequest } from './get-order-detail-new/get-order-detail-new.request';
import { GetOrderDetailNewResponse } from './get-order-detail-new/get-order-detail-new.response';
import { GenerateOrderTransportLabelsRequest } from './pdfs/generate-order-transport-labels/generate-order-transport-labels.request';
import { GenerateOrderTransportLabelsResponse } from './pdfs/generate-order-transport-labels/generate-order-transport-labels.response';
import { GenerateOrderReceiptWithShipLabelPdfsRequest } from './pdfs/generate-order-receipt-with-ship-label-pdfs/generate-order-receipt-with-ship-label-pdfs.request';
import { GenerateOrderReceiptWithShipLabelPdfsResponse } from './pdfs/generate-order-receipt-with-ship-label-pdfs/generate-order-receipt-with-ship-label-pdfs.response';
import { UpdateOrderAddressRequest } from './update-order-address/update-order-address.request';
import { UpdateOrderAddressResponse } from './update-order-address/update-order-address.response';
import { ReadyForShippingRequest } from './ready-for-shipping/ready-for-shipping.request';
import { ReadyForShippingResponse } from './ready-for-shipping/ready-for-shipping.response';
import { GetOrderForOperationRequest } from './get-order-for-operation/get-order-for-operation.request';
import { GetOrderForOperationResponse } from './get-order-for-operation/get-order-for-operation.response';
import { GetOperationProcessStepsRequest } from './get-operation-process-steps/get-operation-process-steps.request';
import { GetOperationProcessStepsResponse } from './get-operation-process-steps/get-operation-process-steps.response';
import { GetOrderItemSummariesRequest } from './get-order-item-summaries/get-order-item-summaries.request';
import { GetOrderItemSummariesResponse } from './get-order-item-summaries/get-order-item-summaries.response';
import { GetOrderReportsBasedOnItemsWithPaymentsRequest } from './get-order-reports-based-on-items-with-payments/get-order-reports-based-on-items-with-payments.request';
import { GetOrderReportsBasedOnItemsWithPaymentsResponse } from './get-order-reports-based-on-items-with-payments/get-order-reports-based-on-items-with-payments.response';
import { CalculateOrderPricesWithVariantsAndSidesResponse } from './calculate-order-prices-with-variants-and-sides/calculate-order-prices-with-variants-and-sides.response';
import { CalculateOrderPricesWithVariantsAndSidesRequest } from './calculate-order-prices-with-variants-and-sides/calculate-order-prices-with-variants-and-sides.request';
import { GenerateOrderReceiptV2Request } from './pdfs/generate-order-receipt-v2/generate-order-receipt-v2.request';
import { GenerateOrderReceiptV2Response } from './pdfs/generate-order-receipt-v2/generate-order-receipt-v2.response';
import { ExportOperationProcessStepsRequest } from './export-operation-process-steps/export-operation-process-steps.request';
import { ExportOperationProcessStepsResponse } from './export-operation-process-steps/export-operation-process-steps.response';
import { GetOrdersForAdminNewRequest } from './get-orders-for-admin-new/get-orders-for-admin-new.request';
import { GetOrdersForAdminNewResponse } from './get-orders-for-admin-new/get-orders-for-admin-new.response';
import { GetOrdersForSellerNewRequest } from './get-orders-for-seller-new/get-orders-for-seller-new.request';
import { GetOrdersForSellerNewResponse } from './get-orders-for-seller-new/get-orders-for-seller-new.response';
import {PassiveOrderRequest} from "./passive-order/passive-order-request";
import {ResponseBase} from "../../../models/_commons/responses/response-base";

class OrderService extends HttpBaseService {
	constructor() {
		super('orders');
	}

	async getExternalOrderById(request: GetExternalOrderByIdRequest): Promise<GetExternalOrderByIdResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getOrderDetail(request: GetOrderDetailRequest): Promise<GetOrderDetailResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getOrderDetailNew(request: GetOrderDetailNewRequest): Promise<GetOrderDetailNewResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getOrdersForAdmin(request: GetOrdersForAdminRequest): Promise<GetOrdersForAdminResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getOrdersForAdminNew(request: GetOrdersForAdminNewRequest): Promise<GetOrdersForAdminNewResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async getOrdersBySellerId(request: GetOrdersBySellerIdRequest): Promise<GetOrdersBySellerIdResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getOrdersForSellerNew(request: GetOrdersForSellerNewRequest): Promise<GetOrdersForSellerNewResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async getOrderDetailByBarcode(request: GetOrderDetailByBarcodeRequest): Promise<GetOrderDetailByBarcodeResponse> {
		return await this.getData(request.getConvertUriExtension);
	}


	async passiveOrder(request: PassiveOrderRequest): Promise<ResponseBase> {
		return await this.getData(request.getConvertUriExtension);
	}

	//#region Generate PDF Operations
	async generateOrderDetailPdfs(request: GenerateOrderDetailPdfsRequest): Promise<GenerateOrderDetailPdfsResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async generateOrderItemBarcodePdfs(request: GenerateOrderItemBarcodePdfsRequest): Promise<GenerateOrderItemBarcodePdfsResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async generateOrderReceiptPdfs(request: GenerateOrderReceiptPdfsRequest): Promise<GenerateOrderReceiptPdfsResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async generateOrderReceiptV2(request: GenerateOrderReceiptV2Request): Promise<GenerateOrderReceiptV2Response> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async generateAllOrderDataPdfs(request: GenerateOrderPdfsRequest): Promise<GenerateOrderPdfsResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async generateOrderTransportLabels(request: GenerateOrderTransportLabelsRequest): Promise<GenerateOrderTransportLabelsResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async generateOrderReceiptWithShipLabelPdfs(request: GenerateOrderReceiptWithShipLabelPdfsRequest): Promise<GenerateOrderReceiptWithShipLabelPdfsResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getOrderDesignArchive(request: GetOrderDesignArchiveRequest): Promise<GetOrderDesignArchiveResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
	//#endregion

	async getOrdersForPrintCenter(request: GetOrdersForPrintCenterRequest): Promise<GetOrdersForPrintCenterResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async createManuelOrder(request: CreateManuelOrderRequest, uploadAction: (state: ProgressEvent) => void): Promise<CreateManuelOrderResponse> {
		return await this.postData(request.getConvertUriExtension, request, true, uploadAction);
	}

	async updateOrderAddress(request: UpdateOrderAddressRequest): Promise<UpdateOrderAddressResponse> {
		return await this.putData(request.getConvertUriExtension, request);
	}

	async setReadyForShipping(request: ReadyForShippingRequest): Promise<ReadyForShippingResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async getOrderForOperation(request: GetOrderForOperationRequest): Promise<GetOrderForOperationResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getOperationProcessSteps(request: GetOperationProcessStepsRequest): Promise<GetOperationProcessStepsResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}

	async exportOperationProcessSteps(request: ExportOperationProcessStepsRequest): Promise<ExportOperationProcessStepsResponse> {
		return await this.postData(request.getConvertUriExtension, request)
	}

	async getOrderItemSummaries(request: GetOrderItemSummariesRequest): Promise<GetOrderItemSummariesResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async getOrderReportsBasedOnItemsWithPayments(request: GetOrderReportsBasedOnItemsWithPaymentsRequest): Promise<GetOrderReportsBasedOnItemsWithPaymentsResponse> {
		return await this.getData(request.getConvertUriExtension);
	}

	async calculateOrderPricesWithVariantsAndSidesResponse(request: CalculateOrderPricesWithVariantsAndSidesRequest): Promise<CalculateOrderPricesWithVariantsAndSidesResponse> {
		return await this.postData(request.getConvertUriExtension, request);
	}
}

export default new OrderService();
