import { PaginationDto } from "features/_common/dtos/paginations/pagination.dto";
import dtfService from "features/dtfs/_common/dtf.service";
import { GetAllDtfListsRequest } from "features/dtfs/_common/get-all-dtf-lists/get-all-dtf-lists.request";
import { DtfDetails } from "features/dtfs/dtos/dtf-for-list.dto";
import { DtfListStatusType } from "features/dtfs/enums/dtf-list-status-type.enum";
import PrButton from "helpers/widgets/Printram/Forms/Buttons/PrButton";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import styles from "./Dtfs.module.scss";
import { Link } from "react-router-dom";
import dateHelper from "helpers/dateHelper";
import { Pagination } from "models/_commons/responses/pagination";
import { Paginator } from "primereact/paginator";
import { PaginationOrderByEnum } from "features/_common/dtos/paginations/pagination.interface";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";
import { OrderStatuTypes } from "../../../features/orders/dtos/order-statu-types";
import { OrderForListNewDto } from "../../../features/orders/dtos/order-for-list-new.dto";
import DtfDownloadDropdown from "../../../helpers/widgets/Order/Dtf/DtfDownloadDropdown/DtfDownloadDropdown";
import DtfStatuUpdateDropdown from "../../../helpers/widgets/Order/Dtf/DtfStatuUpdateDropdown/DtfStatuUpdateDropdown";
import { SendDtfListToApiRequest } from "../../../features/dtfs/_common/send-dtf-list-to-api/send-dtf-list-to-api.request";
import { DownloadDtfImageRequest } from "../../../features/dtfs/_common/download-dtf-image/download-dtf-image.request";
import { GenerateOrderReceiptDtfRequest } from "../../../features/dtfs/_common/generate-order-receipt-dtf/generate-order-receipt-dtf.request";
import UploadDtf from "./components/UploadDtf";
import { ReadyToPrintDtfListRequest } from "../../../features/dtfs/_common/ready-to-print-dtf-list/ready-to-print-dtf-list.request";
import { confirmDialog } from "primereact/confirmdialog";
import { CancelDtfListRequest } from "../../../features/dtfs/_common/cancel-dtf-list/cancel-dtf-list.request";
import { ApproveDtfListRequest } from "../../../features/dtfs/_common/approve-dtf-list/approve-dtf-list.request";
import SearchInput from "../../../helpers/widgets/Inputs/SearchInput/SearchInput";
import DtfSearchDialog from "./components/DtfSearchDialog";
import { DownloadAllDtfFilesRequest } from "../../../features/dtfs/_common/download-all-dtf-files/download-all-dtf-files-request";

const Dtfs = () => {
  const fromStatePagination = useAppSelector(
    (_state) =>
      _state.pagination.forAdminDtfListPaginationInfo as PaginationDto,
  );

  const [dtfs, setDtfs] = useState<DtfDetails[]>([]);
  const [filterDtfStatusType, setFilterDtfStatusType] = useState<
    DtfListStatusType | undefined
  >(2);
  const [pagination, setPagination] = useState<PaginationDto>({
    pageNumber: 1,
    itemCount: 20,
    orderBy: 2,
    first: 0,
  });
  const [paginationResponse, setPaginationResponse] = useState<
    Pagination | undefined
  >(undefined);
  const [uploadVisible, setUploadVisible] = useState<boolean>(false);
  const [selectedDtfs, setSelectedDtfs] = useState<DtfDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [searchDialogVisible, setSearchDialogVisible] =
    useState<boolean>(false);

  const [dtfStatus] = useState([
    { label: "Preparing", value: 2 },
    { label: "Processing", value: 3 },
    { label: "Checking", value: 4 },
    { label: "Ready To Print", value: 5 },
    { label: "Completed", value: 6 },
    { label: "Canceled", value: 7 },
  ]);

  const getDtfs = async (paginationReq: PaginationDto) => {
    try {
      setLoading(true);

      const request = new GetAllDtfListsRequest({
        pagination: paginationReq,
        dtfListStatusType: filterDtfStatusType,
      });
      const response = await dtfService.getAllDtfLists(request);
      if (!response.isSuccess) throw "";

      setDtfs(response.data || []);
      setPaginationResponse(response.pagination);
    } catch (error) {
    } finally {
      setSelectedDtfs([]);
      setLoading(false);
    }
  };

  const statusIsActive = (status: number) => {
    if (status === filterDtfStatusType) return true;
    else {
      if (status === 2 && filterDtfStatusType === undefined) return true;
      else return false;
    }
  };

  const setPaginationOrderBy = () =>
    setPagination({
      pageNumber: 1,
      itemCount: 20,
      orderBy: pagination.orderBy === 2 ? 1 : 2,
      first: 0,
    });

  //	Methods for bulk operations

  const buttonsHandlerWithStatus = () => {
    if (filterDtfStatusType === 2) {
      //Preparing
      return ["send"];
    } else if (filterDtfStatusType === 3) {
      //Processing
      return [];
    } else if (filterDtfStatusType === 4) {
      //Testing
      return ["download", "upload"];
    } else if (filterDtfStatusType === 5 ||filterDtfStatusType === 6) {
      //Completed
      return ["download"];
    } else return [];
  };

  const getDtfDetail = () => {};

  const showCancelDtf = () => {
    confirmDialog({
      header: "Cancel DTF List",
      icon: "pi pi-info-circle",
      message: "Are you sure you want to cancel the dtf list?",
      acceptLabel: "Confirm",
      rejectLabel: "No",
      acceptClassName: "px-4",
      rejectClassName: "p-button-text px-4",
      accept: () => cancelDtf(),
    });
  };

  const downloadDTF = async () => {
    if (selectedDtfs.length === 0) return;

    try {
      setLoading(true);

      const request = new DownloadAllDtfFilesRequest(
          selectedDtfs.map(_dtf => _dtf.id),
      );

      const response = await dtfService.downloadAllDtfFiles(request);

      if (!response.isSuccess || !response.data) throw "";

      window.location.href = response.data;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const sendtoDTF = async () => {
    if (selectedDtfs.length === 0) return;

    try {
      setLoading(true);

      const request = new SendDtfListToApiRequest(
        selectedDtfs.map((_dtf) => _dtf.id),
      );
      const response = await dtfService.sendDtfListToApi(request);

      if (!response.isSuccess) throw "";

      getDtfs(pagination);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const cancelDtf = async () => {
    if (selectedDtfs.length === 0) return;

    setLoading(true);

    try {
      const request = new CancelDtfListRequest(
        selectedDtfs.map((_dtf) => _dtf.id),
      );
      const response = await dtfService.cancelDtfList(request);

      if (!response.isSuccess) throw "";

      getDtfs(pagination);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const completeDTF = async () => {
    if (selectedDtfs.length === 0) return;

    try {
      setLoading(true);

      const request = new ApproveDtfListRequest(
        selectedDtfs.map((_dtf) => _dtf.id),
      );
      const response = await dtfService.approveDtfList(request);

      if (!response.isSuccess) throw "";

      getDtfs(pagination);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const dtfReady = async () => {
    if (selectedDtfs.length === 0) return;

    try {
      setLoading(true);
      const request = new ReadyToPrintDtfListRequest(
        selectedDtfs.map((_dtf) => _dtf.id),
      );
      const response = await dtfService.readyToPrintDtfList(request);

      if (!response.isSuccess) throw "";

      getDtfs(pagination);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!pagination) return;

    getDtfs(pagination);
  }, [pagination]);

  useEffect(() => {
    if (filterDtfStatusType === undefined) return;

    getDtfs({
      pageNumber: 1,
      itemCount: 20,
      orderBy: pagination.orderBy,
      first: 0,
    });
  }, [filterDtfStatusType]);

  return (
    <main className="container relative">
      <section className="container-header">
        <h1 className="container-header-title">Dtf Lists</h1>

        <div className="container-header-tools">
          {buttonsHandlerWithStatus().includes("send") ? (
            <PrButton
              text="Send to DTF"
              disabled={loading || selectedDtfs.length === 0}
              onClick={() => sendtoDTF()}
              type="secondary"
              icon={<span className="pi pi-fw pi-send" />}
            />
          ) : null}

          {buttonsHandlerWithStatus().includes("download") ? (
              <PrButton
                  text="Download DTF"
                  disabled={loading || selectedDtfs.length === 0}
                  onClick={() => downloadDTF()}
                  type="secondary"
                  icon={<span className="pi pi-fw pi-download" />}
              />
          ) : null}

          {filterDtfStatusType !== 7 && (
            <DtfStatuUpdateDropdown
              disabled={loading || selectedDtfs.length === 0}
              dtfId={"dtfId" || ""}
              showCancel={() => showCancelDtf()}
              completeDTF={() => completeDTF()}
              dtfReady={() => dtfReady()}
              status={filterDtfStatusType || 0}
            />
          )}

          <PrButton
            type="secondary"
            text="Refresh Dtfs"
            disabled={loading}
            icon={<span className="pi pi-fw pi-refresh" />}
            onClick={() => getDtfs(pagination)}
          />
        </div>
      </section>

      <section className="container-body p-3">
        <div className={styles.status}>
          <h5 className="font-bold">Dtf List Status Filter</h5>
          <div className={styles.filters}>
            {dtfStatus.map((_status) => (
              <div
                key={_status.value}
                onClick={() => setFilterDtfStatusType(_status.value)}
                className={`${styles.filter} ${statusIsActive(_status.value) ? styles.active : ""}`}
              >
                <span>{_status.label}</span>
              </div>
            ))}
          </div>
        </div>
        <hr />
        <div className="mb-3">
          You can search among dtf lists by{" "}
          <span className="font-bold">order code</span>.
        </div>
        <div className="mt-4">
          <SearchInput
            onChange={(e) => setSearchFilter(e.target.value)}
            value={searchFilter}
            onKeyDown={(event) =>
              event.key === "Enter" && setSearchDialogVisible(true)
            }
            filterBtn
            disabled={loading}
            placeholder="Search DTF's by order code"
            filterBtnOnClick={() => setSearchDialogVisible(true)}
          />
        </div>
      </section>

      <section className="container-body p-0">
        <div className={styles.dtfsTableWrapper}>
          <div className={styles.dtfsTableHeader}>
            <div className={styles.dtfsTableHeaderTitles}>
              <div className={styles.ordersTableHeaderSelectAll}>
                <Checkbox
                  inputId="selectAll"
                  name="selectAll"
                  checked={
                    selectedDtfs.length === dtfs.length && dtfs.length > 0
                  }
                  onChange={(event) =>
                    setSelectedDtfs(event.checked ? dtfs : [])
                  }
                />
              </div>

              <span className={styles.dtfsTableHeaderTitlesName}>Name</span>
              {/* <span className={styles.dtfsTableHeaderTitlesImage}>Image</span> */}
              <span className={styles.dtfsTableHeaderTitlesStatus}>Status</span>
              <span
                className={styles.dtfsTableHeaderTitlesDate}
                onClick={() => setPaginationOrderBy()}
              >
                Created Date
                <i className="pi pi-fw pi-sort ml-1" />
              </span>
            </div>
          </div>
        </div>

        <div className={styles.dtfsTableBody}>
          {dtfs.length > 0 ? (
            dtfs.map((_dtf) => (
              <Link
                to={_dtf.id}
                key={_dtf.id}
                className={`${styles.dtfsTableBodyItemWrapper}`}
              >
                <div className="px-4">
                  <div className={styles.dtfsTableBodyWrapper}>
                    <div className={styles.dtfsTableBodyItems}>
                      <Checkbox
                        inputId={`selectItem${_dtf.id}`}
                        name={`selectItem${_dtf.id}`}
                        checked={
                          !!selectedDtfs.find((_sDtf) => _sDtf.id === _dtf.id)
                        }
                        onChange={(event) =>
                          setSelectedDtfs((_current) => {
                            let newCurrent = structuredClone(_current);

                            if (event.checked) newCurrent.push(_dtf);
                            else
                              newCurrent = newCurrent.filter(
                                (_nCurrent: DtfDetails) =>
                                  _nCurrent.id !== _dtf.id,
                              );

                            return newCurrent;
                          })
                        }
                      />

                      <div className={styles.dtfsTableBodyItemName}>
                        <span className={styles.dtfsTableBodyItemNameTitle}>
                          {_dtf.name}
                        </span>
                      </div>

                      {/* <div className={styles.dtfsTableBodyItemImage}>
												<img src={_dtf.thumbnail} alt={_dtf.name} height={50} />
											</div> */}

                      <div className={`${styles.dtfsTableBodyItemStatus}`}>
                        <span>{_dtf.dtfListStatusName}</span>
                      </div>
                      <div className={styles.dtfsTableBodyItemDate}>
                        <span>
                          Created at &#x2022;{" "}
                          {dateHelper.formatDate(_dtf.createdDate)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div
              className={`${styles.dtfsTableBodyItemWrapper} ${styles.loadMore}`}
            >
              <p>You dont have any dtf yet</p>
            </div>
          )}

          {!!paginationResponse &&
          paginationResponse.totalItemCount > 0 &&
          dtfs.length > 0 ? (
            <div
              className={`${styles.dtfsTableBodyItemWrapper} ${styles.loadMore}`}
            >
              <Paginator
                template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate={`${!!paginationResponse ? "Showing {first} to {last} of {totalRecords}" : ""}`}
                first={pagination.first}
                rows={pagination.itemCount || 20}
                totalRecords={paginationResponse.totalItemCount}
                rowsPerPageOptions={[10, 20, 30]}
                onPageChange={(event) => {
                  setPagination({
                    first: event.first,
                    itemCount: event.rows,
                    pageNumber: event.page + 1,
                    orderBy: PaginationOrderByEnum.Descending,
                  });
                }}
              />
            </div>
          ) : null}
        </div>
      </section>

      {loading ? (
        <div className="pr-loading-sticky">
          <div className="pr-spinner-wrapper">
            <ProgressSpinner className="p-progress-color" strokeWidth="4" />
          </div>
        </div>
      ) : null}

      <UploadDtf
        visible={uploadVisible}
        setVisible={setUploadVisible}
        dtfId={"dtfId" || ""}
        getDtfDetail={getDtfDetail}
      />

      <DtfSearchDialog
        query={searchFilter}
        visible={searchDialogVisible}
        onHide={() => setSearchDialogVisible(false)}
      />
    </main>
  );
};

export default Dtfs;
