import { User } from '../models/dtos/auth/users/user';

const getMenuByRoles = (user: User | undefined) => {
	if (user?.roles.isPackager)
		return [
			{
				label: 'Main',
				icon: 'pi pi-fw pi-home',
				items: [{ label: 'Barcode Operations', icon: 'pi pi-fw pi-align-justify', to: '/' }]
			}
		];

	if (user?.roles.isDtfManager)
		return [
			{
				label: 'Main',
				icon: 'pi pi-fw pi-home',
				items: [{ label: 'Dtf Lists', icon: 'pi pi-fw pi-print', to: '/' }]
			}
		];

	const dashboardMenu = {
		label: 'Main',
		icon: 'pi pi-fw pi-home',
		items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }]
	};

	const sellerMenu = [
		{ ...dashboardMenu },
		{
			label: 'Pages',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Product Catalog', icon: 'pi pi-fw pi-tags', to: '/product/catalog' },
				{ label: 'Orders', icon: 'pi pi-fw pi-shopping-cart', to: '/product/seller/orders?status=2' },
				{ label: 'Re Orders', icon: 'pi pi-fw pi-shopping-cart', to: '/seller/re-orders' },
				{ label: 'My Prices', icon: 'pi pi-fw pi-tag', to: '/seller/my-prices' },
				{ label: 'Tickets', icon: 'pi pi-fw pi-send', to: '/tickets' }
			]
		}
	];

	sellerMenu.map((menu) => {
		if (menu.label !== 'Pages') return menu;

		if (user?.subscription?.planAndHelper?.helper.isHaveRecordedTraining) menu.items.push({ label: 'Resources', icon: 'pi pi-video', to: '/resources' });
	});

	const merchantMenu = [
		{ ...dashboardMenu },
		{
			label: 'Pages',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Product Catalog', icon: 'pi pi-fw pi-tags', to: '/product/catalog' },
				{ label: 'Tickets', icon: 'pi pi-fw pi-send', to: '/tickets' },
				{ label: 'My Products', icon: 'pi pi-fw pi-tags', to: '/product/merchant/list' }
			]
		}
	];

	const printCenterMenu = [
		{ ...dashboardMenu },
		{
			label: 'Pages',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Product Catalog', icon: 'pi pi-fw pi-tags', to: '/product/catalog' },
				{ label: 'Orders', icon: 'pi pi-fw pi-box', to: 'product/print-center/orders' },
				{ label: 'My Stocks', icon: 'pi pi-fw pi-tags', to: 'product/print-center/stocks' },
				{ label: 'Stock Management', icon: 'pi pi-fw pi-box', to: 'product/print-center/stock-management' },
				{ label: 'Assigned Stores', icon: 'pi pi-fw pi-building', to: '/assigned-stores' },
				{ label: 'Tickets', icon: 'pi pi-fw pi-send', to: '/tickets' }
			]
		}
	];

	const adminMenu = [
		{ ...dashboardMenu },
		{
			label: 'Pages',
			icon: 'pi pi-fw pi-star-fill',
			items: [{ label: 'Product Catalog', icon: 'pi pi-fw pi-tags', to: '/product/catalog' }]
		},
		{
			label: 'Product Management',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Products', icon: 'pi pi-fw pi-box', to: '/products' },
				{ label: 'Prices', icon: 'pi pi-fw pi-tags', to: '/product-prices' },
				{ label: 'Brands', icon: 'pi pi-fw pi-star', to: '/brands' },
				{ label: 'Models', icon: 'pi pi-fw pi-tag', to: '/models' },
				{ label: 'Categories', icon: 'pi pi-fw pi-tags', to: '/categories' },
				{ label: 'Template Products', icon: 'pi pi-fw pi-box', to: '/template-products' },
				{ label: 'Template Categories', icon: 'pi pi-fw pi-images', to: '/template-categories' },
				{ label: 'Colors', icon: 'pi pi-fw pi-palette', to: '/colors' },
				{ label: 'Sizes', icon: 'pi pi-fw pi-sort-alt-slash', to: '/sizes' },
				{ label: 'Assigned Products', icon: 'pi pi-fw pi-directions', to: '/assigned-products' }
			]
		},
		{
			label: 'Stock Management',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Printram Stocks', icon: 'pi pi-fw pi-tags', to: '/stocks/printram' },
				{ label: 'Print Center Stocks', icon: 'pi pi-fw pi-tags', to: '/stocks/print-center' },
				{ label: 'Stock Management', icon: 'pi pi-fw pi-database', to: '/stocks/stock-management' },
				{ label: 'Locations', icon: 'pi pi-fw pi-building', to: '/locations' }
			]
		},
		{
			label: 'Order Management',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Orders', icon: 'pi pi-fw pi-box', to: '/orders?status=3' },
				{ label: 'Re Orders', icon: 'pi pi-fw pi-box', to: '/re-orders' },
				{ label: 'Dtf Lists', icon: 'pi pi-fw pi-print', to: '/dtfs' },
				// { label: 'Barcode Scan', icon: 'pi pi-fw pi-qrcode', to: '/barcode-scan' },
				{ label: 'Barcode Operations', icon: 'pi pi-fw pi-align-justify', to: '/barcodes/' },
				{ label: 'Shipping Informations', icon: 'pi pi-fw pi-car', to: '/shipping-informations' }
			]
		},
		{
			label: 'User Management',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Surveys', icon: 'pi pi-fw pi-file', to: '/surveys' },
				{ label: 'Sellers', icon: 'pi pi-fw pi-box', to: '/sellers' },
				{ label: 'Seller Stores', icon: 'pi pi-fw pi-building', to: '/seller-stores' },
				{ label: 'Seller Groups', icon: 'pi pi-fw pi-users', to: '/seller-groups' },
				{ label: 'Etsy Connections', icon: 'pi pi-fw pi-link', to: '/etsy-connections' },
				{ label: 'Merchants', icon: 'pi pi-fw pi-tags', to: '/merchants' },
				{ label: 'Print Centers', icon: 'pi pi-fw pi-desktop', to: '/print-centers' },
				{ label: 'Tickets', icon: 'pi pi-fw pi-send', to: '/tickets' },
				{ label: 'Reorder Tickets', icon: 'pi pi-fw pi-send', to: '/re-order-tickets' },
				{ label: 'Contacts', icon: 'pi pi-fw pi-envelope', to: '/contacts' },
				{ label: 'Resources', icon: 'pi pi-fw pi-video', to: '/resources' },
				{ label: 'Plans', icon: 'pi pi-fw pi-id-card', to: '/plans' }
			]
		},
		{
			label: 'Get In Touch Requests',
			icon: 'pi pi-fw pi-star-fill',
			items: [
				{ label: 'Get In Touch Forms', icon: 'pi pi-fw pi-send', to: '/forms/get-in-touch-forms' },
				{ label: 'Boost Potential Forms', icon: 'pi pi-fw pi-send', to: '/forms/boost-potential-forms' }
			]
		}
	];

	const menu = user?.roles.isAdmin ? adminMenu : user?.roles.isSeller ? sellerMenu : user?.roles.isMerchant ? merchantMenu : printCenterMenu;

	const lastMenu = [
		...menu,
		{
			label: 'Help',
			icon: 'pi pi-fw pi-home',
			items: [
				{
					label: 'Free Mockups & Designs',
					icon: 'pi pi-fw pi-external-link',
					command: () => {
						const aElement = document.createElement('a');
						aElement.href = 'https://drive.google.com/drive/folders/1UtDhe69NbsVuXyMZJAfZB2_ARVl80cnL';
						aElement.target = '_blank';
						aElement.click();
						aElement.remove();
					}
				},
				{
					label: 'FAQ - Printram (TR)',
					icon: 'pi pi-fw pi-external-link',
					command: () => {
						const aElement = document.createElement('a');
						aElement.href = 'https://printram.blob.core.windows.net/important/FAQ-PRINTRAM.pdf';
						aElement.target = '_blank';
						aElement.click();
						aElement.remove();
					}
				},
				{
					label: 'FAQ - Etsy (TR)',
					icon: 'pi pi-fw pi-external-link',
					command: () => {
						const aElement = document.createElement('a');
						aElement.href = 'https://printram.blob.core.windows.net/important/FAQ-ETSY.pdf';
						aElement.target = '_blank';
						aElement.click();
						aElement.remove();
					}
				},
				{
					label: 'How to open a store on ETSY? (TR)',
					icon: 'pi pi-fw pi-external-link',
					command: () => {
						const aElement = document.createElement('a');
						aElement.href = 'https://printram.blob.core.windows.net/important/EtsyGuide.pdf';
						aElement.target = '_blank';
						aElement.click();
						aElement.remove();
					}
				}
			]
		}
	];

	return lastMenu;
};

const menuHelper = { getMenuByRoles };

export default menuHelper;
