import { SyncOrderContext, SyncOrderContextType } from 'components/pages/orders/sync-order/context/SyncOrderContextProvider';
import countryService from 'features/countries/country.service';
import { GetCountriesRequest } from 'features/countries/get-countries/get-countries.request';
import { CountryBaseDto } from 'features/countries/get-countries/get-countries.response';
import { CreateEtsySynchronizeProductsWithSidesRequest } from 'features/integrations/_common/create-etsy-synchronize-products-with-sides/create-etsy-synchronize-products-with-sides.request';
import { CreateModelRequestDto } from 'features/integrations/_common/create-etsy-synchronize-products-with-sides/dtos/create-model.dto';
import { SyncEtsyProduct } from 'features/integrations/_common/create-etsy-synchronize-products-with-sides/dtos/sync-etsy-product.dto';
import { CalculateItems, CalculateOrderPricesWithVariantsAndSidesRequest } from 'features/orders/_common/calculate-order-prices-with-variants-and-sides/calculate-order-prices-with-variants-and-sides.request';
import { prefixOptions } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import orderService from 'features/orders/_common/order.service';
import { GetShippingRatesRequest } from 'features/orders/derived-features/order-transports/get-shipping-rates/get-shipping-rates.request';
import { orderTransportService } from 'features/orders/derived-features/order-transports/order-transport.service';
import { ErrorMessage, Form, Formik } from 'formik';
import stringHelper from 'helpers/string.helper';
import toastHelper from 'helpers/toast.helper';
import PrDropdown from 'helpers/widgets/Printram/Dropdown/PrDropdown';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import AutoCompleteInput from 'helpers/widgets/Printram/Forms/Input/AutoCompleteInput';
import PrTextInput from 'helpers/widgets/Printram/Forms/Input/PrTextInput';
import PrTextareaInput from 'helpers/widgets/Printram/Forms/Input/PrTextareaInput';
import React, { useEffect, useState } from 'react';
import { getPrintramStores } from 'redux/features/printram-store/printramStoreSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as Yup from 'yup';

export const ShipForm = () => {
	const stores = useAppSelector((_state) => _state.printramStore.data || []);
	const dispatch = useAppDispatch();

	const context = React.useContext(SyncOrderContext) as SyncOrderContextType;

	const [countries, setCountries] = useState<CountryBaseDto[]>([]);

	const getCountries = async () => {
		try {
			const response = await countryService.getCountries(new GetCountriesRequest());

			if (!response.isSuccess || !response.data) throw '';

			setCountries(response.data);

			if (!!context.request.createModel.shipTo && context.request.createModel.shipTo.countryIso.length === 2) {
				const createModel = structuredClone(context.request.createModel);

				const country = response.data.find((_country) => _country.iso2 === createModel.shipTo?.countryIso);

				if (!!country) {
					createModel.shipTo!.countryIso = country.iso3;

					context.setRequest(new CreateEtsySynchronizeProductsWithSidesRequest({ createModel }));
				}
			}
		} catch (error) {
			setCountries([]);
		}
	};

	useEffect(() => {
		getCountries();



		dispatch(getPrintramStores(context.request.createModel.sellerId));
	}, []);

	return (
		<Formik
			initialValues={context.request.createModel}
			enableReinitialize
			validationSchema={Yup.object().shape({
				shipTo: Yup.object().shape({
					receiverFullName: Yup.string()
						.required('Full Name is required')
						.test('is-full-name', 'Enter your Full Name', (value) => {
							if (!value) return false;

							return value.trim().split(' ').length > 1;
						}),
					receiverEmail: Yup.string().email('Email address must be valid.').required('Email is required').min(5, 'Email must be at least 5 characters.').max(75, 'Email must be a maximum of 75 characters.'),
					formattedAddress: Yup.string().required('Search adress is required'),
					countryIso: Yup.string().required('Select a country'),
					state: Yup.string().required('State is required'),
					firstLine: Yup.string().required('Address Line 1 is required'),
					city: Yup.string().required('City is required'),
					zip: Yup.string().required('Zip Code is required')
				})
			})}
			onSubmit={async (values) => {
				const request: CreateEtsySynchronizeProductsWithSidesRequest = structuredClone(context.request);

				request.createModel = structuredClone(values);

				try {
					context.setLoading(true);

					const variantAndQuantities: { [id: string]: number } = {};

					for (const item of request.createModel.products.filter((_prod) => _prod.sides.length > 0)) {
						if (!!variantAndQuantities[item.productVariantId]) {
							variantAndQuantities[item.productVariantId] += item.quantity;

							continue;
						}

						variantAndQuantities[item.productVariantId] = item.quantity;
					}

					const shipRateRequest = new GetShippingRatesRequest({ shipTo: structuredClone(request.createModel.shipTo) || undefined, variantAndQuantities });

					if (!!shipRateRequest.shipTo.receiverPhoneNumber) shipRateRequest.shipTo.receiverPhoneNumber = shipRateRequest.shipTo.phonePrefix.value + shipRateRequest.shipTo.receiverPhoneNumber;
					else shipRateRequest.shipTo.receiverPhoneNumber = null;

					const response = await orderTransportService.getShippingRates(shipRateRequest);

					if (!response.data || !response.isSuccess) throw '';

					const minPriceRate = Math.min(...response.data.map((_rate) => +_rate.amount));

					const minRate = response.data.find((_rate) => +_rate.amount === minPriceRate);

					const items: CalculateItems[] = request.createModel.products.map((_product: SyncEtsyProduct) => ({ uniqueIdentifier: _product.externalProductId, variantId: _product.productVariantId, sideIdentities: _product.sides.map((_side) => _side.productPrintSideId), quantity: _product.quantity }));

					const rateRequest = new CalculateOrderPricesWithVariantsAndSidesRequest({
						shippingRate: minRate,
						shipTo: structuredClone(request.createModel.shipTo),
						items: items.filter((_item) => _item.sideIdentities.length > 0)
					});

					if (!!rateRequest?.shipTo?.receiverPhoneNumber) rateRequest.shipTo.receiverPhoneNumber = rateRequest.shipTo.phonePrefix.value + rateRequest.shipTo.receiverPhoneNumber;
					if (rateRequest.shipTo?.receiverPhoneNumber === '') rateRequest.shipTo.receiverPhoneNumber = null;

					const rateResponse = await orderService.calculateOrderPricesWithVariantsAndSidesResponse(rateRequest);

					if (!rateResponse.isSuccess || !rateResponse.data) throw '';

					context.setShippingRates(response.data);

					context.setSelectedRate(minRate);

					context.setIsShowShippingForm(false);

					context.setCalculatedPrice(rateResponse.data);

					context.setIsNewShippingRatesCalculateRequired(false);
				} catch (error) {
					if (typeof error === 'string') toastHelper.warning(error);
				} finally {
					context.setLoading(false);
					context.setRequest(request);
				}
			}}>
			{(form) => (
				<Form>
					<div className="container-body p-0 flex">
						<div className="flex-1 p-5">
							<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
								Recipient
							</h2>

							<div>
								<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
									Printram Store *
								</label>
								<PrDropdown options={stores.map((_store) => ({ label: _store.name, value: _store.storeId }))} value={stores.map((_store) => ({ label: _store.name, value: _store.storeId })).find((_store) => _store.value === context.selectedStore?.storeId)} disabled placeholder="Select store" className="w-full" />
							</div>

							<div className="mt-4">
								<PrTextInput
									type="text"
									label="Full Name *"
									style={{ height: '2.375rem' }}
									value={form.values.shipTo?.receiverFullName}
									onChange={(e) => {
										const formattedName = stringHelper.formatName(e.target.value);

										form.setFieldValue('shipTo.receiverFullName', formattedName);
									}}
								/>

								<ErrorMessage name="shipTo.receiverFullName" component="small" className="text-red font-medium" />
							</div>

							<div className="mt-4">
								<PrTextInput
									type="email"
									label="Email *"
									value={form.values.shipTo?.receiverEmail}
									style={{ height: '2.375rem' }}
									onChange={(e) => {
										form.setFieldValue('shipTo.receiverEmail', e.target.value || '');
									}}
								/>

								<ErrorMessage name="shipTo.receiverEmail" component="small" className="text-red font-medium" />
							</div>

							<label className="mt-4 block" style={{ fontSize: '19px', fontWeight: '600' }}>
								Phone <span className="pr-input-label-optional">(Optional)</span>
							</label>

							<div className="flex gap-2 align-items-end mt-1">
								<div>
									<PrDropdown
										options={prefixOptions}
										value={form.values.shipTo?.phonePrefix}
										className="w-7rem"
										onChange={(e) => {
											form.setFieldValue('shipTo.phonePrefix', e);
										}}
									/>
								</div>

								<PrTextInput
									name="phone"
									type="tel"
									value={form.values.shipTo?.receiverPhoneNumber || ''}
									className="w-full"
									style={{ height: '2.375rem' }}
									onChange={(e) => {
										if (e.target.value.length > 10) return;

										if (e.target.value.length > 0 && !/^\d+$/.test(e.target.value)) return;

										form.setFieldValue('shipTo.receiverPhoneNumber', e.target.value);
									}}
								/>
							</div>

							<div className="mt-4">
								<PrTextareaInput
									label="Customer Note"
									value={form.values.customerNote || ''}
									rows={2}
									required={false}
									onChange={(e) => {
										form.setFieldValue('customerNote', e.target.value || null);
									}}
								/>
							</div>

							{/* <div className="mt-4">
								<PrTextareaInput
									label="Seller Note"
									value={form.values.sellerNote || ''}
									rows={2}
									required={false}
									onChange={(e) => {
										form.setFieldValue('sellerNote', e.target.value || null);
									}}
								/>
							</div> */}

							<div className="mt-4">
								<PrTextareaInput
									label="Gift Message"
									value={form.values.giftMessage || ''}
									rows={2}
									required={false}
									onChange={(e) => {
										form.setFieldValue('giftMessage', e.target.value || null);
									}}
								/>
							</div>
						</div>

						<div className="flex-1 border-left-1 border-400 p-5">
							<h2 className="mb-5" style={{ fontSize: '1.5rem' }}>
								Shipping address
							</h2>

							{countries.length > 0 ? (
								<div className="mt-4">
									<AutoCompleteInput
										label="Search Address *"
										value={form.values.shipTo?.formattedAddress}
										defaultValue={!!form.values.shipTo && form.values.shipTo.formattedAddress.length > 1 ? form.values.shipTo.formattedAddress : ''}
										style={{ height: '2.375rem' }}
										onChange={(e) => form.setFieldValue('shipTo.formattedAddress', e.target.value)}
										onPlaceSelectedDto={(e) => {
											form.setFieldValue('shipTo.formattedAddress', e.formattedAddress);
											form.setFieldValue('shipTo.state', e.state);
											form.setFieldValue('shipTo.city', e.city);
											form.setFieldValue('shipTo.zip', e.zip);
											form.setFieldValue('shipTo.firstLine', e.firstLine);
											form.setFieldValue('shipTo.secondLine', e.secondLine);

											for (const country of countries) {
												if (country.iso2 !== e.countryIso2) continue;

												form.setFieldValue('shipTo.countryIso', country.iso3);
												break;
											}
										}}
									/>

									<ErrorMessage name="shipTo.formattedAddress" component="small" className="text-red font-medium" />
								</div>
							) : null}

							<div className="mt-4 flex gap-4">
								<div className="w-full">
									<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
										Country *
									</label>

									<div>
										<PrDropdown
											value={countries.map((_country) => ({ label: _country.name, value: _country.iso3 })).find((_country) => _country.value === form.values.shipTo?.countryIso)}
											options={countries.map((_country) => ({ label: _country.name, value: _country.iso3 }))}
											className="w-full"
											onChange={(e) => {
												form.setFieldValue('shipTo.countryIso', e.value);
											}}
										/>

										<ErrorMessage name="shipTo.countryIso" component="small" className="text-red font-medium" />
									</div>
								</div>

								<div className="w-full">
									<label className="block mb-1" style={{ fontSize: '19px', fontWeight: '600' }}>
										State *
									</label>

									<PrTextInput
										value={form.values.shipTo?.state || ''}
										className="w-full"
										style={{ height: '2.375rem' }}
										onChange={(e) => {
											form.setFieldValue('shipTo.state', e.target.value || '');
										}}
									/>

									<ErrorMessage name="shipTo.state" component="small" className="text-red font-medium" />
								</div>
							</div>

							<div className="mt-4">
								<PrTextInput
									label="Address Line 1 *"
									value={form.values.shipTo?.firstLine || ''}
									style={{ height: '2.375rem' }}
									onChange={(e) => {
										form.setFieldValue('shipTo.firstLine', e.target.value || '');
									}}
								/>

								<ErrorMessage name="shipTo.firstLine" component="small" className="text-red font-medium" />
							</div>

							<div className="mt-4">
								<PrTextInput
									label="Address Line 2"
									required={false}
									value={form.values.shipTo?.secondLine || ''}
									style={{ height: '2.375rem' }}
									onChange={(e) => {
										form.setFieldValue('shipTo.secondLine', e.target.value || null);
									}}
								/>
							</div>

							<div className="mt-4 flex gap-4">
								<div className="w-full">
									<PrTextInput
										label="City *"
										value={form.values.shipTo?.city || ''}
										style={{ height: '2.375rem' }}
										onChange={(e) => {
											form.setFieldValue('shipTo.city', e.target.value || '');
										}}
									/>

									<ErrorMessage name="shipTo.city" component="small" className="text-red font-medium" />
								</div>

								<div className="w-full">
									<PrTextInput
										label="Zip Code *"
										value={form.values.shipTo?.zip || ''}
										style={{ height: '2.375rem' }}
										onChange={(e) => {
											form.setFieldValue('shipTo.zip', e.target.value || '');
										}}
									/>

									<ErrorMessage name="shipTo.zip" component="small" className="text-red font-medium" />
								</div>
							</div>

							<div className="mt-4">
								<PrTextInput
									label="VAT number"
									required={false}
									value={form.values.shipTo?.vatNumber || ''}
									style={{ height: '2.375rem' }}
									onChange={(e) => {
										form.setFieldValue('shipTo.vatNumber', e.target.value || '');
									}}
								/>
							</div>
						</div>
					</div>

					<div className="container-body p-3 h-6rem flex align-items-center justify-content-center">
						<PrButton text={!!context.calculatedPrice ? 'Update & Calculate Shipping' : 'Calculate Shipping'} btnType="submit" />
					</div>
				</Form>
			)}
		</Formik>
	);
};
