import { useContext, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { MegaMenu } from 'primereact/megamenu';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { CSSTransition } from 'react-transition-group';
import { RTLContext } from '../App';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { logout } from '../redux/features/auth/authSlice';
import AvatarImage from '../helpers/widgets/AvatarImage';
import menuHelper from '../helpers/menu.helper';
import React from 'react';

const AppTopbar = (props: any) => {
	const user = useAppSelector((state) => state.auth.data?.user);

	const isRTL = useContext(RTLContext);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	// Fixed for 6.1.0
	// eslint-disable-next-line
	const searchPanel = useRef(null);

	useEffect(() => {
		// Fixed for 6.1.0
		/*if (props.searchActive) {
			searchPanel.current.element.focus();
		}*/
	}, [props.searchActive]);

	const handleLogout = () => dispatch(logout());

	const onInputKeydown = (event: any) => {
		const key = event.which;

		//escape, tab and enter
		if (key === 27 || key === 9 || key === 13) {
			props.onSearch(false);
		}
	};

	const menu = menuHelper.getMenuByRoles(user).map((item) => {
		item.items.map((child: any) => {
			child.command = () => navigate(child.to);
			return child;
		});
		return item;
	});

	const model = [
		{
			label: 'PAGES',
			items: [menu]
		}
	];

	return (
		<div className="layout-topbar shadow-4">
			<div className="layout-topbar-left">
				<button type="button" style={{ cursor: 'pointer' }} className="layout-topbar-logo p-link" onClick={() => navigate('/')}>
					<img id="app-logo" src="assets/layout/images/logo-light.svg" alt="Printram Logo" style={{ height: '10rem' }} />
				</button>

				<button type="button" className="hidden lg:flex layout-menu-button shadow-6 p-link" onClick={props.onMenuButtonClick}>
					<i className="pi pi-chevron-right"></i>
				</button>

				<button type="button" className="flex lg:hidden layout-topbar-mobile-button p-link">
					<i className="pi pi-align-justify fs-large" onClick={props.onMenuButtonClick}></i>
				</button>
			</div>

			<div className={classNames('layout-topbar-right', { 'layout-topbar-mobile-active': props.mobileTopbarActive })}>
				<div className="layout-topbar-actions-left">
					<MegaMenu model={model} className="layout-megamenu" />
				</div>
				<div className="layout-topbar-actions-right">
					<ul className="layout-topbar-items">
						<li className="layout-topbar-item layout-search-item">
							<button className="layout-topbar-action rounded-circle p-link" onClick={() => props.onSearch(true)}>
								<i className="pi pi-search fs-large"></i>
							</button>
							<CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.searchActive} unmountOnExit>
								<div className="layout-search-panel p-inputgroup">
									<span className="p-inputgroup-addon">
										<i className="pi pi-search"></i>
									</span>
									<InputText type="text" placeholder="Search" onKeyDown={onInputKeydown} />
									<span className="p-inputgroup-addon">
										<Button type="button" icon="pi pi-times" className="p-button-rounded p-button-text p-button-plain" onClick={() => props.onSearch(false)}></Button>
									</span>
								</div>
							</CSSTransition>
						</li>

						<li className="layout-topbar-item">
							<button className="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-link" onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: 'profile' })}>
								<AvatarImage />
							</button>

							<CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'profile'} unmountOnExit>
								<ul className="layout-topbar-action-panel shadow-6">
									{!user?.roles.isPackager ? (
										<React.Fragment>
											<li onClick={() => navigate('/account/details')} className="layout-topbar-action-item">
												<button className="flex flex-row align-items-center p-link">
													<i className={classNames('pi pi-user', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
													<span>My Account</span>
												</button>
											</li>
											{user?.roles.isSeller ? (
												<React.Fragment>
													{/* <li onClick={() => navigate('/account/connections')} className="layout-topbar-action-item">
												<button className="flex flex-row align-items-center p-link">
													<i className={classNames('pi pi-arrow-right-arrow-left', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
													<span>Connections</span>
												</button>
											</li> */}
													<li onClick={() => navigate('/account/my-stores')} className="layout-topbar-action-item">
														<button className="flex flex-row align-items-center p-link">
															<i className={classNames('pi pi-building', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
															<span>My Stores</span>
														</button>
													</li>
													{/* <li onClick={() => navigate('/account/credit-cards')} className="layout-topbar-action-item">
												<button className="flex flex-row align-items-center p-link">
													<i className={classNames('pi pi-credit-card', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
													<span>Payment Methods</span>
												</button>
											</li> */}
													<li onClick={() => navigate('/account/plans')} className="layout-topbar-action-item">
														<button className="flex flex-row align-items-center p-link">
															<i className={classNames('pi pi-id-card', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
															<span>Plans</span>
														</button>
													</li>
													<li onClick={() => navigate('/account/payments')} className="layout-topbar-action-item">
														<button className="flex flex-row align-items-center p-link">
															<i className={classNames('pi pi-wallet', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
															<span>Payments</span>
														</button>
													</li>
													{/* <li onClick={() => navigate('/account/print-options')} className="layout-topbar-action-item">
														<button className="flex flex-row align-items-center p-link">
															<i className={classNames('pi pi-file-export', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
															<span>Print Options</span>
														</button>
													</li> */}
												</React.Fragment>
											) : null}

											<li onClick={() => navigate('/account/security')} className="layout-topbar-action-item">
												<button className="flex flex-row align-items-center p-link">
													<i className={classNames('pi pi-shield', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
													<span>Security</span>
												</button>
											</li>
											<li onClick={() => navigate('/account/settings')} className="layout-topbar-action-item">
												<button className="flex flex-row align-items-center p-link">
													<i className={classNames('pi pi-cog', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
													<span>Settings</span>
												</button>
											</li>
										</React.Fragment>
									) : null}

									<li onClick={handleLogout} className="layout-topbar-action-item">
										<button className="flex flex-row align-items-center p-link">
											<i className={classNames('pi pi-power-off', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
											<span>Logout</span>
										</button>
									</li>
								</ul>
							</CSSTransition>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default AppTopbar;
