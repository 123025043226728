import { Menu } from '@headlessui/react';
import { AxiosError } from 'axios';
import { DeliveryTypes } from 'features/orders/_common/create-manuel-order/create-manuel-order.request';
import { GetOrderDetailNewRequest } from 'features/orders/_common/get-order-detail-new/get-order-detail-new.request';
import orderService from 'features/orders/_common/order.service';
import { CreateOrderStatuHistoryRequest } from 'features/orders/derived-features/order-statu-histories/create-order-statu-history/create-order-statu-history.request';
import orderStatuHistoriesService from 'features/orders/derived-features/order-statu-histories/order-statu-histories.service';
import { OrderStatuTypes } from 'features/orders/dtos/order-statu-types';
import dateHelper from 'helpers/dateHelper';
import stringHelper from 'helpers/string.helper';
import toastHelper from 'helpers/toast.helper';
import PrintDropdown from 'helpers/widgets/Order/PrintDropdown/PrintDropdown';
import ShippingInfo from 'helpers/widgets/Order/ShippingInfo';
import PrButton from 'helpers/widgets/Printram/Forms/Buttons/PrButton';
import { OrderDto, OrderTicketDto } from 'models/dtos/order/order';
import React, { useEffect, useRef, useState } from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { BsArrowRight, BsBrush } from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import CreateOrderStatuHistoryModal from '../orderManagement/orders/modals/CreateOrderStatuHistoryModal';
import styles from './OrderDetail.module.scss';
import ProductTable from './components/ProductTable';
import CreateOrderStatuHistoryAcknowledgeModal from './components/modals/CreateOrderStatuHistoryAcknowledgeModal';
import UpdateShippingAddressModal from './components/modals/UpdateShippingAddressModal';
// import { ForListProduct, ForListVariant } from './create-order/CreateOrder';
import orderItemService from 'features/orders/derived-features/order-items/order-item.service';
import { SoftDeleteOrderItemRequest } from 'features/orders/derived-features/order-items/soft-delete-order-item/soft-delete-order-item.request';
import { PrLoading } from 'helpers/widgets/Loading/PrLoading';
import CreateTicket from '../tickets/components/CreateTicket';
import WantAcknowledgeModal from './components/modals/WantAcknowledgeModal';
import { ProductPrices } from './components/products-prices/ProductPrices';
import { OrderDetailTitle } from './components/order-detail-title/OrderDetailTitle';
import { OrderDetailInfo } from './components/order-detail-info/OrderDetailInfo';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';

export const OrderStatusColors = [
	{ orderStatu: OrderStatuTypes.PendingApproval, color: '#ED7D31' },
	{ orderStatu: OrderStatuTypes.OrderApproved, color: '#3f51b5' },
	{ orderStatu: OrderStatuTypes.UrgentOrder, color: '#6C5F5B' },
	{ orderStatu: OrderStatuTypes.PrintedOrder, color: '#75bef8' },
	{ orderStatu: OrderStatuTypes.OnTheWay, color: '#fdd87d' },
	{ orderStatu: OrderStatuTypes.UrgentOrder, color: '#90cd93' },
	{ orderStatu: OrderStatuTypes.Canceled, color: '#ff8980' },
	{ orderStatu: OrderStatuTypes.Preparing, color: '#75bef8' }
];

const OrderDetail = () => {
	const user = useAppSelector((_state) => _state.auth.data?.user);
	const { orderId } = useParams();
	const navigate = useNavigate();

	const statusContainerRef = useRef<HTMLDivElement>(null);

	const [loadingText, setLoadingText] = useState<string>('Loading');
	const [deleteLoading, setDeleteLoading] = useState<{ loading: boolean; orderItemId: string } | undefined>(undefined);
	const [wantAcknowledgeVisible, setWantAcknowledgeVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [statuLoading, setStatuLoading] = useState<boolean>(false);
	const [changeShippingAddressVisible, setChangeShippingAddressVisible] = useState<boolean>(false);
	const [statuModalVisible, setStatuModalVisible] = useState<boolean>(false);
	const [statuAcknowledgeModalVisible, setStatuAcknowledgeModalVisible] = useState<boolean>(false);
	const [ticketVisible, setTicketVisible] = useState<boolean>(false);
	const [isDragging, setIsDragging] = useState<boolean>(false);
	const [startX, setStartX] = useState<number>(0);
	const [scrollLeft, setScrollLeft] = useState<number>(0);
	const [scrollWidth, setScrollWidth] = useState<number>(0);
	const [order, setOrder] = useState<OrderDto | null>(null);
	const [selectedStatus, setSelectedStatu] = useState<OrderStatuTypes>(OrderStatuTypes.Unknown);
	const [tutorialStepsEnabled, setTutorialStepsEnabled] = useState<boolean>(false);

	const steps = [
	{
		element: '.tutorial-selector1',
		intro: '<p>New Feature</p>If there is an issue with your created order (such as sending the wrong product, a printing error, or a defective product), you can recreate the order using "Create Reorder."'
	}]

	const getOrderDetail = async (showDialog: boolean = true) => {
		try {
			setLoadingText('Loading Product');
			setLoading(true);
			if (!orderId) throw '';

			const response = await orderService.getOrderDetailNew(new GetOrderDetailNewRequest(orderId));
			if (!response.isSuccess) throw '';

			//console.clear();

			setOrder(response.data);

			if (response.data?.statu.type === OrderStatuTypes.PendingApproval && user?.roles.isSeller && showDialog) setWantAcknowledgeVisible(true);
		} catch (error) {
			setOrder(null);

			if (error instanceof AxiosError && error.response?.status === 500) navigate(-1);
		} finally {
			setLoading(false);
		}
	};

	const updateOrderStatus = async (request: Partial<CreateOrderStatuHistoryRequest>) => {
		try {
			setLoadingText('Status Updating');
			setStatuLoading(true);

			const response = await orderStatuHistoriesService.create(new CreateOrderStatuHistoryRequest({ ...request }));

			if (!response.isSuccess) throw '';

			await getOrderDetail();
		} catch (error) {
		} finally {
			setStatuLoading(false);
		}
	};

	const ticketStatuHandler = (ticket: OrderTicketDto) => {
		if (ticket.isActive) {
			if (ticket.isAnswered) return 'Answered';
			else return 'Waiting';
		} else return 'Closed';
	};

	const statuStyleHandler = (ticket: OrderTicketDto) => {
		if (ticket.isActive) {
			if (!ticket.isAnswered) return styles.isWaitingStatus;
		} else return styles.isClosedStatus;
	};

	useEffect(() => {
		if (!orderId) return;

		getOrderDetail();
	}, [orderId]);

	useEffect(() => {
		if (scrollWidth === 0 || !statusContainerRef.current || statusContainerRef.current.scrollLeft === scrollWidth) return;

		const width = statusContainerRef.current.getBoundingClientRect().width;
		const currentScrollWidth = statusContainerRef.current.scrollWidth;

		if (currentScrollWidth > width) statusContainerRef.current.classList.remove('pointer-events-none');
		else statusContainerRef.current.classList.add('pointer-events-none');

		statusContainerRef.current.scrollLeft = scrollWidth;
	}, [scrollWidth]);

	useEffect(() => {
		if (!order || order.statu.type < 3) return;

		setTutorialStepsEnabled(true)
	},[order])

	return (
		<main className="container relative">
			{/* <Steps enabled={tutorialStepsEnabled} steps={steps} initialStep={0} onExit={() => setTutorialStepsEnabled(false)} onComplete={() => setTutorialStepsEnabled(false)} options={{ showBullets: false, dontShowAgain: true, dontShowAgainCookie: 'order-detail', disableInteraction: false }} /> */}

			<section className="container-header">
				{!!order ? <OrderDetailTitle {...order} /> : null}

				<div className="container-header-tools">
					{window.history.length > 1 && <PrButton text="Go Back" onClick={() => navigate(-1)} type="secondary" icon={<span className="pi pi-fw pi-arrow-left" />} />}
					{!!order && order.statu.type >= 3 ? (
						<React.Fragment>
							{!order.ticket ? <PrButton text="Create Ticket" onClick={() => setTicketVisible(true)} icon={<span className="pi pi-fw pi-send" />} /> : null}
							<PrButton
								text="Create Reorder"
								type="secondary"
								onClick={() => {
									navigate(`/re-order/${orderId}`, { state: { order: order } });
								}}
								className='tutorial-selector1'
							/>
							{order.statu.type !== OrderStatuTypes.PendingApproval ? <PrintDropdown loading={loading} setLoading={setLoading} orderIds={orderId as string} skipTypes={['Barcode', 'All']} /> : null}
						</React.Fragment>
					) : null}
				</div>
			</section>

			{order?.ticket && (
				<section className="container-body p-3 z-1">
					<div className={styles.ticketsHeader}>
						<div>
							<h4 className="m-0">Ticket Information</h4>
							<p className="text-sm text-600">The ticket information details are provided below.</p>
						</div>
						<Link to={`/ticket/${order.ticket?.ticketId}`} target="_blank">
							<PrButton text="Go Ticket Detail" type="secondary" icon={<span className="pi pi-external-link" />} />
						</Link>
					</div>

					<div className={styles.tickets}>
						<div className={styles.ticketsCard}>
							<h6>Ticket Code</h6>
							<div>{order.ticket.ticketCode}</div>
						</div>

						<div className={styles.ticketsCard}>
							<h6>Title</h6>
							<div>{order.ticket.title}</div>
						</div>

						<div className={styles.ticketsCard}>
							<h6>Created Date</h6>
							<div>{dateHelper.formatDate(order.ticket.createdDate)}</div>
						</div>

						<div className={styles.ticketsCard}>
							<h6>Status</h6>
							<div className={`${styles.ticketsStatus} ${statuStyleHandler(order.ticket)}`}>
								<span>{ticketStatuHandler(order.ticket)}</span>
							</div>
						</div>
					</div>

					<div className={`${styles.ticketsCard} mt-2`}>
						<h6>Last Comment</h6>
						<div>
							<div>
								<span className="font-bold">From: </span>
								{order.ticket.lastCommentOwnerFullName}
							</div>
							<div>
								<span className="font-bold">Date: </span>
								{dateHelper.formatDate(order.ticket.lastCommentDate)}
							</div>
						</div>
						<span dangerouslySetInnerHTML={{ __html: order.ticket.lastComment }} className="mt-2" />
					</div>
				</section>
			)}

			<OrderDetailInfo
				order={order || undefined}
				onUpdateAddress={() => setChangeShippingAddressVisible(true)}
				onStatuChange={(event) => {
					if (event.value === OrderStatuTypes.OrderApproved && order?.deliveryType === DeliveryTypes.PickUp) {
						updateOrderStatus({ orderId, orderStatuId: event.value, reason: '' });
						return;
					}

					setSelectedStatu(event.value as number);

					event.value === OrderStatuTypes.Canceled ? setStatuModalVisible(true) : setStatuAcknowledgeModalVisible(true);
				}}
			/>

			<section className="container-body p-3 z-4">
				<h4 className="m-0">Status History</h4>
				<p className="text-sm text-600">The order status history details are provided below.</p>

				{!!order ? (
					<div
						ref={(_ref) => {
							if (!_ref) return;

							setScrollWidth(_ref.scrollWidth);

							return ((statusContainerRef.current as any) = _ref);
						}}
						className={`${styles.detailWrapper} ${styles.scrollable}`}
						onMouseUp={() => setIsDragging(false)}
						onMouseDown={(event) => {
							if (!statusContainerRef.current) return;

							setIsDragging(true);

							setStartX(event.pageX - statusContainerRef.current.offsetLeft);
							setScrollLeft(statusContainerRef.current.scrollLeft);
						}}
						onMouseLeave={() => setIsDragging(false)}
						onMouseMove={(event) => {
							if (!isDragging || !statusContainerRef.current) return;

							const x = event.pageX - statusContainerRef.current.offsetLeft;
							const walk = x - startX;
							statusContainerRef.current.scrollLeft = scrollLeft - walk;
						}}>
						{order?.statuHistories.map((_history, _index) => (
							<div key={_index} className="flex align-items-center justify-content-center gap-1 w-full">
								<div className={`${styles.detailItem} w-full h-full`}>
									<div className={styles.detailItemHeader} style={{ color: OrderStatusColors.find((statu) => statu.orderStatu === (_history.statu.type as any as OrderStatuTypes))?.color }}>
										<h6>{stringHelper.parseAtUpperCaseAndJoin(_history.statu.readableName)}</h6>
									</div>

									<p className="flex flex-column">
										<span className="mt-auto">{dateHelper.formatDate(_history.createdDate)}</span>

										{!!_history.reason ? <span>{_history.reason}</span> : null}
									</p>
								</div>

								{order.statuHistories.length - 1 !== _index ? <BsArrowRight /> : null}
							</div>
						))}
					</div>
				) : null}
			</section>

			<section className="container-body p-3 z-2">
				<h4 className="m-0">Products</h4>
				<p className="text-sm text-600">Your order's products are displayed in the table below.</p>

				{!!order ? <ProductTable orderItems={order.items} /> : null}
			</section>

			{!!order ? <ProductPrices prices={order.prices} /> : null}

			<section className="container-body p-3 z-1">
				<h4 className="m-0">Shipping Information</h4>
				<p className="text-sm text-600">The order shipping information details are provided below.</p>

				{!!order && !!order.transport ? (
					<ShippingInfo {...order.transport} />
				) : (
					<div className="h-7rem flex align-items-center justify-content-center" style={{ backgroundColor: '#fafafa', border: '1px solid #eaeaea', borderRadius: '0.2rem' }}>
						<h5 className="m-0 text-color-secondary">
							{!!order && order.statu.type === OrderStatuTypes.PendingApproval
								? "In order to view the transport information, the order's status needs to be changed to 'Order Approved'."
								: !!order?.orderAttachmentFiles && order.orderAttachmentFiles.length > 0
								? order.orderAttachmentFiles.map((_attachment, index) => (
										<a key={index} href={_attachment.filePath} target="_blank" className="text-primary">
											{index + 1}. Open label
										</a>
								  ))
								: 'Shipping Information not found'}
						</h5>
					</div>
				)}
			</section>

			<CreateOrderStatuHistoryModal data={{ statu: selectedStatus, orderId: orderId || '' }} visible={statuModalVisible} setVisible={setStatuModalVisible} callback={updateOrderStatus} />
			<CreateOrderStatuHistoryAcknowledgeModal orderId={orderId} visible={statuAcknowledgeModalVisible} setVisible={setStatuAcknowledgeModalVisible} callback={updateOrderStatus} />

			<WantAcknowledgeModal
				visible={wantAcknowledgeVisible}
				setVisible={setWantAcknowledgeVisible}
				order={order || undefined}
				onAccept={() => {
					if (!!order && order.deliveryType === DeliveryTypes.PickUp) {
						updateOrderStatus({ orderId, orderStatuId: OrderStatuTypes.OrderApproved, reason: '' });
						return;
					}

					setSelectedStatu(OrderStatuTypes.OrderApproved);
					setStatuAcknowledgeModalVisible(true);
				}}
			/>

			{!!order ? <UpdateShippingAddressModal order={order} visible={changeShippingAddressVisible} setVisible={setChangeShippingAddressVisible} callback={getOrderDetail} /> : null}

			{!!order ? <CreateTicket createVisible={ticketVisible} setCreateVisible={setTicketVisible} orderId={order.id} getTickets={getOrderDetail} /> : null}

			<PrLoading loading={loading || statuLoading} text={loadingText} />
		</main>
	);
};

export default OrderDetail;
