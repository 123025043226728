import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import { useState } from 'react';
import PrButton from '../../../../../../../../../helpers/widgets/Printram/Forms/Buttons/PrButton';
import { Button } from 'primereact/button';
import {
	ValidateAddressDTO,
	ValidateAddressResponseDataDTO
} from '../../../../../../../../../features/orders/derived-features/order-transports/validate-address/validate-address-response';

type Props = {
	visible: boolean;
	validateAddressResponse?: ValidateAddressResponseDataDTO;
	onSubmit: (address: ValidateAddressDTO) => void;
	onHide: () => void;
};

const FormatAddressDialog = ({ visible, validateAddressResponse, onSubmit, onHide }: Props) => {
	const [selectedAddress, setSelectedAddress] = useState<ValidateAddressDTO | null>(validateAddressResponse?.recommendedAddress ?? null);

	const handleAddressSelection = (address: ValidateAddressDTO | null) => {
		setSelectedAddress(address);
	};

	const renderAddressCard = (label: string, isSelected: boolean, address: ValidateAddressDTO | null) => (
		<div
			role="button"
			style={{
				cursor: 'pointer',
				backgroundColor: isSelected ? '#4541FF0D' : 'transparent'
			}}
			onClick={() => handleAddressSelection(address)}
			className={`col flex flex-row p-4 border-solid border-1 border-round-md mx-2 ${isSelected ? 'border-primary' : 'border-gray-300'}`}>
			<RadioButton inputId={`address-${label}`} name="address" checked={isSelected} className="text-primary" />
			<div className="flex flex-column ml-2">
				<label htmlFor={`address-${label}`} className="mb-3 font-bold h-2rem">
					{label}
				</label>
				<div>{address?.addressLine1}</div>
				<div>{`${address?.cityLocality} ${address?.stateProvince}`}</div>
				<div>{address?.postalCode}</div>
			</div>
		</div>
	);

	const renderHeader = () => (
		<div className="ml-2">
			<h4>Update recipient address</h4>
			<div style={{ width: '100%', height: '1px', backgroundColor: '#B4B4B4' }}></div>
		</div>
	);

	return (
		<Dialog visible={visible} onHide={() => onHide()} onShow={() => setSelectedAddress(validateAddressResponse?.recommendedAddress!)} header={renderHeader()} style={{ width: '600px' }}>
			<div className="flex flex-column">
				<div className="flex flex-column justify-content-start align-items-start w-full pb-5 mb-auto">
					<p className="mb-5 mx-2">This recipient address cannot be verified. Use the suggested address to avoid issues with your shipment.</p>
					<div className="flex flex-row justify-content-center align-content-stretch w-full">
						{renderAddressCard('Original address', selectedAddress === validateAddressResponse?.originalAddress, validateAddressResponse?.originalAddress || null)}
						{renderAddressCard('Recommended address', selectedAddress === validateAddressResponse?.recommendedAddress, validateAddressResponse?.recommendedAddress || null)}
					</div>
				</div>

				<div className="flex flex-column justify-content-center align-items-center">
					<div
						style={{
							width: '540px',
							height: '1px',
							backgroundColor: '#B4B4B4',
							marginBottom: '1rem'
						}}></div>
					<div className="flex flex-row justify-content-between align-items-center w-full">
						<Button label="Edit Address" className="p-button-text" onClick={() => onHide()} />
						<PrButton className="mr-2" text="Continue" onClick={() => onSubmit(selectedAddress!)} />
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default FormatAddressDialog;
