export enum OrderStatuTypes {
	Unknown = 1,
	PendingApproval = 2,
	OrderApproved = 3,
	UrgentOrder = 4,
	PrintedOrder = 5,
	OnTheWay = 6,
	Delivered = 7,
	Canceled = 8,
	// CreatingDtf = 9,
	// WaitingPaymentForDtf = 10,
	// PayToDtf = 11,
	DeliveredToCarrier = 12,
	Preparing = 13
}